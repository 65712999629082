/** Application-wide styling: external style library imports, HTML element overrides, font rules, & CSS classes
 *  See `angular.json` for additional stylesheets
 */
/* stylelint-disable no-invalid-position-at-import-rule */
@use 'sass:meta';

// TaskTrain custom variables & third-party variable overrides (import first to override third-party `!default` variables)
@import 'assets/styles/variables';

// https://github.com/primefaces/primeng/issues/13757#issuecomment-1790568974
@layer primeng {
	// [Bootstrap](https://getbootstrap.com/docs/5.3/getting-started/introduction/
	@import 'bootstrap/scss/functions';
	@import 'bootstrap/scss/variables';
	@import 'bootstrap/scss/mixins';
	@import 'bootstrap/scss/maps';
	@import 'bootstrap/scss/utilities';
	@import 'bootstrap/scss/containers';
	@import 'bootstrap/scss/root';
	@import 'bootstrap/scss/reboot';
	@import 'bootstrap/scss/grid';
	@import 'bootstrap/scss/helpers';
	@import 'bootstrap/scss/utilities/api';
}

// [Font Awesome](https://fontawesome.com/v4.7.0/)
@import 'font-awesome/css/font-awesome.min.css';

// [PrimeNG](https://www.primefaces.org/primeng/showcase/#/) + TaskTrain overrides
@import 'assets/styles/global/primeng';
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';

// [Helipopper](https://github.com/ngneat/helipopper)
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/animations/scale.css';
@import 'assets/styles/common/helipopper';

// TaskTrain application-wide styles (import last to override third-party selectors)
@import 'assets/styles/global/global';
