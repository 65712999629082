/* stylelint-disable font-family-no-missing-generic-family-keyword, no-duplicate-selectors, selector-class-pattern, selector-no-vendor-prefix, selector-not-notation */
@use 'sass:color';
@import '../variables'; // Allow overrides with project variables

/* Base styling adapted from PrimeNG original SCSS
   @ToDo: integrate with CSS from Rhea theme & remove `stylelint-disable no-duplicate-selectors`
 */
@layer primeng {
	@mixin hover-element() {
		background-color: $tt-color-hover;
		border-color: $tt-input-text-border-color-hover;
		color: $tt-color-black;

		a {
			color: $tt-color-black;
		}
	}

	.p-component {
		font-family: $tt-font-family;
		font-feature-settings: var(--font-feature-settings, normal);
		font-size: $tt-font-size;
		font-weight: normal;

		input,
		select,
		textarea,
		button {
			font-family: $tt-font-family;
			font-size: $tt-font-size;
		}

		:active {
			outline: none;
		}
	}

	.p-component-overlay {
		background: rgb(215 222 228 / 50%);
		opacity: 1 !important;
		transition-duration: 0.2s;
	}


	.p-disabled, .p-component:disabled {
		opacity: 0.6 !important;;
	}

	.p-error {
		color: $tt-color-danger;
	}

	.p-text-secondary {
		color: $tt-color-secondary;
	}

	.pi {
		font-size: 1rem;
	}

	.p-icon {
		width: 1rem;
		height: 1rem;
	}

	.p-link {
		font-family: var(--font-family);
		font-feature-settings: var(--font-feature-settings, normal);
		font-size: 1rem;
		border-radius: 2px;
	}

	.p-link:focus-visible {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #e4e9ec;
	}

	.p-component-overlay-enter {
		animation: p-component-overlay-enter-animation 150ms forwards;
	}

	.p-component-overlay-leave {
		animation: p-component-overlay-leave-animation 150ms forwards;
	}

	@keyframes p-component-overlay-enter-animation {
		from {
			background-color: transparent;
		}

		to {
			background-color: var(--maskbg);
		}
	}

	@keyframes p-component-overlay-leave-animation {
		from {
			background-color: var(--maskbg);
		}

		to {
			background-color: transparent;
		}
	}

	/* @TaskTrain: Removed borders for TaskTrain */
	.p-component-content {
		background-color: $tt-content-background-color;
		color: $tt-content-text-color;

		a {
			color: $tt-content-text-color;
		}
	}

	/* @TaskTrain: Removed borders & changed background-color */
	.p-component-header {
		background-color: $tt-header-background-color;
		color: $tt-header-text-color;
		font-weight: normal;

		a {
			color: $tt-header-text-color;
		}
	}

	.p-component-overlay {
		background-color: $tt-color-gray-300;
		filter: color.alpha(opacity=50);
		opacity: 0.5;
	}

	.p-highlight {
		background-color: $tt-color-active;
		border-color: $tt-border-color-active;
		color: $tt-text-color-active;

		a {
			color: $tt-text-color-active;
		}
	}

	.p-focus {
		background-color: $tt-background-color-focus;
		border-color: $tt-border-color-focus;
		color: $tt-text-color-focus;

		a {
			color: $tt-text-color-focus;
		}
	}

	.p-error {
		background-color: $tt-background-color-error;
		border-color: $tt-border-color-error;
		color: $tt-text-color-error;

		a {
			color: $tt-text-color-error;
		}
	}

	.p-component-overlay-enter {
		animation: p-component-overlay-enter-animation 150ms forwards;
	}

	.p-component-overlay-leave {
		animation: p-component-overlay-leave-animation 150ms forwards;
	}

	@keyframes p-component-overlay-enter-animation {
		from {
			background-color: transparent;
		}

		to {
			background-color: rgb(0 0 0 / 40%);
		}
	}

	@keyframes p-component-overlay-leave-animation {
		from {
			background-color: rgb(0 0 0 / 40%);
		}

		to {
			background-color: transparent;
		}
	}

	/* AutoComplete */
	.p-autocomplete .p-autocomplete-loader {
		right: 0.5rem;
	}

	.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
		right: 2.786rem;
	}

	.p-autocomplete:not(.p-disabled):hover .p-autocomplete-multiple-container {
		border-color: $tt-border-color;
	}

	.p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #e4e9ec;
		border-color: $tt-input-text-border-color-focus;
	}

	.p-autocomplete .p-autocomplete-multiple-container {
		padding: 0.5rem;
		gap: 0.5rem;
	}

	.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
		border-color: $tt-input-text-border-color-hover;
	}

	.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #e4e9ec;
		border-color: $tt-input-text-border-color-focus;
	}

	.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
		padding: 0.25rem 0;
	}

	.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
		color: $tt-text-color;
		font-size: 0.875rem;
		margin: 0;
		padding: 0;
	}

	.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
		background: $tt-chip-background-color;
		border-radius: $tt-chips-border-radius;
		color: $tt-text-color;
		padding: 0.25rem 0.5rem;
	}

	.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
		color: $tt-autocomplete-token-icon-color;
		margin-left: 0.25rem;
	}

	.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token.p-focus {
		background: #cbcbcb;
		color: $tt-autocomplete-token-icon-color;
	}

	.p-autocomplete.p-invalid.p-component > .p-inputtext {
		border-color: $tt-input-border-color-error;
	}

	.p-autocomplete-panel {
		background: $tt-content-background-color;
		border: 1px solid $tt-border-color;
		box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
		color: $tt-text-color;
	}

	.p-autocomplete-panel .p-autocomplete-items {
		padding: 0;
	}

	.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
		background: transparent;
		border: 0 none;
		border-radius: 0;
		color: $tt-text-color;
		margin: 0;
		padding: 0.5rem 0.857rem;
		transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}

	.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
		background: $tt-color-hover;
		color: $tt-text-color-hover;
	}

	.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
		color: $tt-text-color;
		background: $tt-background-color-active;
	}

	.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
		padding: 0.5rem 0.857rem;
		color: $tt-text-color;
		background: transparent;
	}

	.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
		margin: 0;
		padding: 0.857rem;
		color: $tt-text-color;
		background: #f4f4f4;
		font-weight: 700;
	}

	p-autocomplete.p-autocomplete-clearable .p-inputtext {
		padding-right: 1.875rem;
	}

	p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
		color: $tt-text-color-muted;
		right: 0.5rem;
	}

	p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon {
		color: $tt-text-color-muted;
		right: 2.786rem;
	}

	.p-float-label input.ng-dirty.ng-invalid ~ label {
		color: $tt-text-color-error;
	}


	/* Adapted from PrimeNG Rhea theme https://github.com/primefaces/primeng/blob/master/src/assets/components/themes/rhea/theme.css */
	.p-button:focus,
	.p-button:enabled:hover,
	.p-fileupload-choose:not(.p-disabled):hover {
		@include hover-element;

		outline: 0 none;
	}

	.p-button:enabled:active,
	.p-fileupload-choose:not(.p-disabled):active {
		background-color: $tt-border-color;
		border: 1px solid $tt-border-color;
		border-color: $tt-border-color;
		color: $tt-text-color-active;
	}

	.p-chkbox-box:not(.p-disabled):not(.p-highlight):hover {
		@include hover-element;
	}

	.p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
		@include hover-element;
	}

	.p-listbox {
		.p-listbox-header {
			.p-listbox-filter-container {
				.fa {
					color: $tt-input-text-color;
				}
			}
		}

		&:not(.p-disabled) {
			.p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
				@include hover-element;
			}

			.p-listbox-item.p-disabled .p-chkbox-box:hover {
				background-color: $tt-input-background-color;
				border-color: $tt-border-color;
			}
		}

		&.p-disabled {
			.p-chkbox-box:not(.p-highlight):hover {
				background-color: $tt-color-default;
				border-color: $tt-border-color;
				color: $tt-color-black;
			}
		}
	}

	.p-multiselect:not(.p-disabled):hover {
		@include hover-element;
	}

	.p-multiselect-panel .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
		@include hover-element;
	}

	.p-multiselect-panel .p-multiselect-item.p-disabled:hover .p-chkbox-box {
		background-color: $tt-input-background-color;
		border-color: $tt-border-color;
	}

	.p-multiselect-panel .p-multiselect-close {
		color: $tt-header-icon-color;
	}

	.p-multiselect-panel .p-multiselect-filter-container .fa {
		color: $tt-input-text-color;
	}

	.p-selectbutton .p-button {
		background: $tt-toggle-button-background-color;
		border: 1px solid $tt-border-color;
		color: $tt-text-color;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}

	.p-selectbutton .p-button .p-button-icon-left,
	.p-selectbutton .p-button .p-button-icon-right {
		color: $tt-text-color;
	}

	.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
		background: $tt-background-color-hover;
		border-color: $tt-border-color-hover;
		color: $tt-text-color-active;
	}

	.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
	.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
		color: $tt-text-color;
	}

	.p-selectbutton .p-button.p-highlight {
		background: $tt-background-color-active;
		border-color: $tt-border-color-active;
		color: $tt-text-color-active;
	}

	.p-selectbutton .p-button.p-highlight .p-button-icon-left,
	.p-selectbutton .p-button.p-highlight .p-button-icon-right {
		color: $tt-content-text-color;
	}

	.p-selectbutton .p-button.p-highlight:hover {
		background: $tt-background-color-hover;
		border-color: $tt-border-color-hover;
		color: $tt-text-color-hover;
	}

	.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
	.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
		color: $tt-text-color-hover;
	}

	p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
		border-color: $tt-border-color-error;
	}

	.p-paginator a:not(.p-disabled):not(.p-highlight):hover {
		@include hover-element;
	}

	.p-paginator a {
		color: $tt-color-black;
	}

	.p-orderlist {
		.p-orderlist-item:not(.p-highlight):hover {
			@include hover-element;
		}
	}

	.p-picklist {
		.p-picklist-item:not(.p-disabled):not(.p-highlight):hover {
			@include hover-element;
		}

		.p-picklist-droppoint-highlight {
			background-color: $tt-background-color-hover;
			border-color: $tt-border-color-hover;
			color: color.adjust($tt-content-text-color, $lightness: -1%);

			a {
				color: color.adjust($tt-content-text-color, $lightness: -1%);
			}
		}

		.p-picklist-highlight {
			border-color: $tt-border-color-hover;
			color: color.adjust($tt-content-text-color, $lightness: -1%);

			a {
				color: color.adjust($tt-content-text-color, $lightness: -1%);
			}
		}
	}

	.p-tree {
		&.p-treenode-dragover {
			border-color: $tt-border-color-hover;
		}

		.p-treenode-content {
			&.p-treenode-selectable {
				.p-treenode-label:not(.p-highlight):hover {
					@include hover-element;
				}
			}

			&.p-treenode-dragover {
				background-color: $tt-background-color-active;
				color: $tt-text-color-active;
			}
		}

		&.p-tree-horizontal {
			.p-treenode-content.p-treenode-selectable {
				.p-treenode-label:not(.p-highlight):hover {
					background-color: inherit;
					color: inherit;
				}

				&:not(.p-highlight):hover {
					@include hover-element;
				}
			}
		}
	}

	.p-fieldset {
		&.p-fieldset-toggleable {
			.p-fieldset-legend:hover {
				@include hover-element;
			}
		}
	}

	.p-panel {
		.p-panel-titlebar {
			.p-panel-titlebar-icon:hover {
				@include hover-element;
			}
		}
	}

	.p-tabview {
		.p-tabview-nav {
			li {
				&:not(.p-highlight):not(.p-disabled):hover {
					@include hover-element;
				}
			}
		}
	}

	.p-dialog {
		.p-dialog-titlebar-icon {
			color: $tt-header-text-color;

			&:hover {
				@include hover-element;
			}
		}
	}

	.p-sidebar {
		.p-sidebar-close {
			color: $tt-header-text-color;

			&:hover {
				@include hover-element;
			}
		}
	}

	.p-inplace {
		.p-inplace-display:focus {
			outline: none; // Probably OK since we highlight the active row
		}
	}

	.p-menuitem {
		.p-menuitem-link {
			color: $tt-content-text-color;

			&:hover {
				@include hover-element;

				border-color: transparent;
			}
		}

		&.p-menuitem-active {
			> .p-menuitem-link {
				@include hover-element;

				border-color: transparent;
			}
		}
	}

	.p-tabmenu {
		.p-tabmenu-nav {
			li:not(.p-highlight):hover {
				@include hover-element;
			}
		}
	}

	.p-steps {
		.p-steps-item:not(.p-highlight):not(.p-disabled):hover {
			@include hover-element;
		}
	}

	.p-panelmenu {
		.p-panelmenu-panel {
			background-color: $tt-color-white;
		}

		.p-panelmenu-header {
			&:not(.p-highlight):hover {
				@include hover-element;

				border-color: $tt-border-color;

				a {
					color: $tt-color-black;
				}
			}

			&.p-highlight {
				a {
					color: $tt-text-color-active;
				}
			}
		}

		.p-panelmenu-content {
			.p-menuitem-link {
				color: $tt-content-text-color;

				&:hover {
					@include hover-element;

					border-color: transparent;
				}
			}
		}
	}

	.p-datepicker {
		.p-datepicker-header {
			a {
				color: $tt-header-text-color;

				&:hover {
					@include hover-element;
				}
			}
		}

		.p-datepicker-calendar {
			td:not(.p-disabled) {
				a:hover {
					@include hover-element;
				}
			}
		}

		.p-monthpicker {
			.p-monthpicker-month:hover {
				@include hover-element;
			}
		}
	}

	.p-rating {
		a {
			color: $tt-input-text-color;
		}
	}

	.p-organizationchart {
		.p-organizationchart-line-down {
			background-color: color.adjust($tt-content-border-color, $lightness: -10%);
		}

		.p-organizationchart-line-left {
			border-right: 1px solid color.adjust($tt-content-border-color, $lightness: -10%);
		}

		.p-organizationchart-line-top {
			border-top: 1px solid color.adjust($tt-content-border-color, $lightness: -10%);
		}

		.p-organizationchart-node-content {
			border-color: color.adjust($tt-content-border-color, $lightness: -10%);

			&.p-organizationchart-selectable-node:not(.p-highlight):hover {
				@include hover-element;
			}
		}

		.p-organizationchart-node-content .p-node-toggler {
			color: color.adjust($tt-content-border-color, $lightness: -10%);
		}
	}

	/* TurboTable */
	.p-table {
		.p-table-thead > tr > th,
		.p-table-tfoot > tr > td {
			background-color: $tt-header-background-color;
			border: 1px solid $tt-border-color;
			color: $tt-color-black;
			padding: 0.478rem;
		}

		.p-table-tbody > tr {
			background-color: $tt-color-white;
			border: 1px solid $tt-border-color;
			color: $tt-color-black;
			height: 2.5rem;
			outline: none;

			> td {
				background-color: inherit;
				border: 1px solid $tt-content-border-color;
			}

			&.p-highlight {
				background-color: $tt-color-hover;
				color: $tt-color-black;
			}

			&.p-contextmenu-selected {
				background-color: color.adjust($tt-color-active, $lightness: 20%);
				color: $tt-color-black;
			}
		}

		.p-sortable-column {
			.p-sortable-column-icon {
				color: $tt-header-text-color;
			}

			&.p-highlight {
				background-color: $tt-header-background-color;
				color: $tt-color-black;
				outline: none;

				.p-sortable-column-icon {
					color: $tt-text-color-hover;
				}
			}

			&:not(.p-highlight):hover {
				background-color: $tt-color-hover;
				color: $tt-color-black;

				.p-sortable-column-icon {
					color: $tt-color-black;
				}
			}
		}

		&.p-table-hoverable-rows {
			.p-table-tbody > tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
				background-color: $tt-color-hover;
				color: $tt-color-black;
				cursor: pointer;
			}
		}
	}

	@media (width <= 35em) {
		.p-table-responsive .p-table-tbody > tr {
			border-bottom: 1px solid $tt-content-border-color;
			border-top: 1px solid $tt-content-border-color;
		}
	}

	/* Row Reorder */
	.p-table .p-table-tbody > tr.p-table-dragpoint-top > td {
		box-shadow: inset 0 2px 0 0 $tt-background-color-hover;
	}

	.p-table .p-table-tbody > tr.p-table-dragpoint-bottom > td {
		box-shadow: inset 0 -2px 0 0 $tt-background-color-hover;
	}

	/* TurboTable */
	.p-treetable {
		.p-treetable-thead > tr > th,
		.p-treetable-tfoot > tr > td {
			background-color: $tt-header-background-color;
			border: 1px solid $tt-border-color;
			color: $tt-color-black;
		}

		.p-treetable-tbody > tr {
			background-color: $tt-content-background-color;
			color: $tt-content-text-color;

			> td {
				background-color: inherit;
				border: 1px solid $tt-content-border-color;
			}

			&.p-highlight {
				background-color: $tt-background-color-hover;
				color: $tt-text-color-hover;

				.p-treetable-toggler {
					color: $tt-text-color-hover;
				}
			}

			&.p-contextmenu-selected {
				background-color: color.adjust($tt-background-color-hover, $lightness: 20%);
				color: $tt-text-color-hover;

				.p-treetable-toggler {
					color: $tt-text-color-hover;
				}
			}

			.p-treetable-toggler {
				color: $tt-content-text-color;
			}
		}

		.p-sortable-column {
			.p-sortable-column-icon {
				color: $tt-header-text-color;
			}

			&.p-highlight {
				background-color: $tt-background-color-hover;
				color: $tt-text-color-hover;

				.p-sortable-column-icon {
					color: $tt-text-color-hover;
				}
			}

			&:not(.p-highlight):hover {
				background-color: $tt-color-hover;
				color: $tt-color-black;

				.p-sortable-column-icon {
					color: $tt-color-black;
				}
			}
		}

		&.p-treetable-hoverable-rows {
			.p-treetable-tbody > tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
				background-color: $tt-color-hover;
				color: $tt-color-black;
				cursor: pointer;
			}
		}
	}

	@media (width <= 35em) {
		.p-treetable-responsive .p-treetable-tbody > tr {
			border-bottom: 1px solid $tt-content-border-color;
			border-top: 1px solid $tt-content-border-color;
		}
	}

	/* Row Reorder */
	.p-treetable .p-treetable-tbody > tr.p-treetable-dragpoint-top > td {
		box-shadow: inset 0 2px 0 0 $tt-background-color-hover;
	}

	.p-treetable .p-treetable-tbody > tr.p-treetable-dragpoint-bottom > td {
		box-shadow: inset 0 -2px 0 0 $tt-background-color-hover;
	}

	/* InputSwitch */
	.p-inputswitch {
		width: 3rem;
		height: 1.75rem;
		border-radius: 1rem;
	}

	.p-inputswitch .p-inputswitch-slider {
		background: $tt-background-color-hover;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		border-radius: 1rem;
		border: 1px solid $tt-border-color;
		outline: none;
	}

	.p-inputswitch .p-inputswitch-slider::before {
		background: $tt-color-white;
		width: 1.25rem;
		height: 1.25rem;
		left: 0.25rem;
		margin-top: -0.625rem;
		border-radius: 50%;
		transition-duration: 0.2s;
	}

	.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
		transform: translateX(1.25rem);
	}

	.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
		background: $tt-color-hover;
	}

	.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
		background: $tt-color-blue-200;
	}

	.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
		background: $tt-color-white;
	}

	.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
		background: $tt-color-hover;
	}

	/* @TaskTrain: Custom changes to prevent line wrapping in small devices */
	.category-keys .chips-auto-wrapper {
		flex-wrap: nowrap;
	}

	.p-autocomplete-token-label {
		white-space: normal;
	}

	.p-fieldset-legend {
		margin-bottom: 0; // Override Bootstrap Reboot for PrimeNG fieldset header `legend` elements
		float: none;
		padding: inherit;

		&:focus {
			outline: none;
		}
	}

	.p-fieldset-toggleable .p-fieldset-legend a {
		outline: none;
	}

	.p-fieldset .p-fieldset-toggler.pi-plus::before {
		content: '\e901' !important; /* this is same as `pi-chevron-right` */
		font-family: PrimeIcons;
		margin-right: 0.1rem;
		vertical-align: middle;
	}

	.p-fieldset .p-fieldset-toggler.pi-minus::before {
		content: '\e902' !important; /* this is same as `pi-chevron-down` */
		font-family: PrimeIcons;
		margin-right: 0.1rem;
		vertical-align: middle;
	}

	.tooltip-full-width.p-tooltip .p-tooltip-text {
		min-width: 13rem;
	}

	.p-fileupload-files {
		display: inline-block;
	}

	// PrimeNG - Inputs
	.p-inputtext.ng-dirty.ng-invalid,
	p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
	p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
	p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext,
	p-chips.ng-dirty.ng-invalid > .p-inputtext,
	p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
	p-checkbox.ng-dirty.ng-invalid .p-chkbox-box,
	p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box,
	p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch,
	p-listbox.ng-dirty.ng-invalid .p-inputtext,
	p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
	p-spinner.ng-dirty.ng-invalid > .p-inputtext,
	p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button,
	p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
		border-bottom-color: $tt-input-border-color-error;
	}

	/* @TaskTrain: Theme styling
	 * customizations based on [PrimeNG Rhea theme](https://github.com/primefaces/primeng/blob/master/src/assets/components/themes/rhea/theme.css)
	 */

	/* Accordion */
	.p-accordion {
		.p-accordion-header {
			background: $tt-header-background-color;
			border: 1px solid $tt-header-border-color;
			color: $tt-header-text-color;
			height: $tt-row-height;
			padding: $tt-header-padding;
			text-transform: uppercase;

			a {
				color: $tt-header-text-color;
				height: 1.2rem;
				outline: none;

				&:hover {
					color: $tt-header-text-color-hover;
					text-shadow: none;
				}
			}

			.p-accordion-header:not(.p-highlight):not(.p-disabled):hover {
				@include hover-element;
			}

			.p-icon {
				margin-right: 0.5rem;
			}
		}

		.p-accordion-header:not(.p-highlight):not(.p-disabled):hover {
			background: $tt-header-background-color;
			border-color: $tt-header-border-color;
		}

		.p-accordion-header.p-highlight {
			background: $tt-header-background-color; // No change for active accordion
			border-left-color: $tt-header-border-color;
			border-radius: 0;
			border-right-color: $tt-header-border-color;
		}

		.p-accordion-content {
			border-bottom: 1px solid $tt-header-border-color !important;
			border-left: 1px solid $tt-header-border-color !important;
			border-right: 1px solid $tt-header-border-color !important;
			padding: $tt-content-padding;
		}
	}

	.p-toggleable-content {
		overflow: hidden !important;
	}

	/* Tabview -- Heavily customized for TaskTrain */
	.tt-scrollable-tabview .p-tabview-nav {
		border-bottom: 1px solid $tt-tabview-border-color;
		flex-wrap: nowrap;
		overflow: auto hidden; // Prevent vertical scrollbar on some browsers

		li {
			flex: 0 0 auto;
			margin: 0;
			text-align: center;
		}
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		background: $tt-tabview-background-color-hover;
		border-left: 1px solid $tt-tabview-border-color;
		border-right: 1px solid $tt-tabview-border-color;
		border-top: 1px solid $tt-tabview-border-color;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		color: $tt-tabview-text-color-selected;
		font-weight: 700;
		height: $tt-row-height;
		margin: 0 0 -1px;
		padding: 0.5rem 1rem;
		transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link:active {
		outline: 0;
	}

	.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
		background: $tt-tabview-background-color-selected;
		border-color: $tt-tabview-border-color;
		color: $tt-tabview-text-color-selected;
		font-weight: bold;
		outline: 0;
	}

	.p-tabview .p-tabview-left-icon {
		margin-right: 0.5rem;
	}

	.p-tabview .p-tabview-right-icon {
		margin-left: 0.5rem;
	}

	.p-tabview .p-tabview-close {
		margin-left: 0.5rem;
	}

	.p-tabview .p-tabview-panels {
		background: $tt-color-white;
		max-height: calc(100vh - 5rem);
		padding: $tt-content-padding;
	}

	/* Breadcrumb */
	.p-breadcrumb {
		padding: 0.5rem 1rem;

		.p-menuitem-text {
			margin-left: 0.5rem; // Give icon space
		}
	}

	.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
		transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
		border-radius: 2px;
	}

	.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: $tt-button-box-shadow;
	}

	.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
		color: $tt-menu-item-text-color;
	}

	.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
		color: $tt-menu-item-text-color;
	}

	.p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
		margin: 0 0.5rem;
		color: $tt-text-color-muted
	}

	.p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
		color: $tt-menu-item-text-color;
	}

	.p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
		color: $tt-text-color-muted;
	}

	/* Spinner */
	.p-spinner:not(.p-disabled) .p-spinner-button:enabled:hover {
		@include hover-element;

		background: $tt-color-blue-500;
		border: 1px solid $tt-color-blue-500;
		color: $tt-color-white;
		outline: 0 none;
	}

	.p-spinner:not(.p-disabled) .p-spinner-button:enabled:active {
		background-color: $tt-background-color-active;
		border: 1px solid $tt-border-color-active;
		color: $tt-text-color-active;
	}

	/* Slider */
	.p-slider {
		background: #838688;
		border: none;
		box-shadow: 0 1px 3px rgb(0 0 0 / 60%) inset;
		position: relative;
		text-align: left;
	}

	.p-slider .p-slider-range {
		background: #14a4ff;
		border: 0;
		border-radius: $tt-border-radius;
		box-shadow: 0 1px 3px rgb(0 0 0 / 60%) inset;
		display: block;
		font-size: 0.7em;
		position: absolute;
		z-index: 1;
	}

	.p-slider .p-slider-handle.p-highlight {
		background-position: -17px 0;
	}

	.p-slider-horizontal {
		height: 6px;
	}

	.p-slider-horizontal .p-slider-handle {
		margin-left: -0.6em;
		top: -2px !important;
	}

	.p-slider-horizontal .p-slider-range {
		height: 100%;
		top: 0;
	}

	.p-slider-horizontal .p-slider-range-min {
		left: 0;
	}

	.p-slider-horizontal .p-slider-range-max {
		right: 0;
	}

	.p-slider-vertical {
		height: 100px;
		width: 0.8em;
	}

	.p-slider-vertical .p-slider-handle {
		left: -0.1em !important;
		margin-bottom: -0.6em;
		margin-left: 0;
	}

	.p-slider-vertical .p-slider-range {
		left: 0;
		width: 100%;
	}

	.p-slider-vertical .p-slider-range-min {
		bottom: 0;
	}

	.p-slider-vertical .p-slider-range-max {
		top: 0;
	}

	/* ToggleButton */
	.p-togglebutton.p-button {
		background: $tt-color-white;
		border: 1px solid $tt-border-color;
		color: $tt-text-color;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}

	.p-togglebutton.p-button .p-button-icon-left,
	.p-togglebutton.p-button .p-button-icon-right {
		color: $tt-text-color;
	}

	.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
		background: $tt-background-color-hover;
		border-color: $tt-border-color-hover;
		color: $tt-text-color-hover;
	}

	.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
	.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
		color: $tt-text-color-hover;
	}

	.p-togglebutton.p-button.p-highlight {
		background: $tt-background-color-active;
		border-color: $tt-border-color-active;
		color: $tt-text-color-active;
	}

	.p-togglebutton.p-button.p-highlight .p-button-icon-left,
	.p-togglebutton.p-button.p-highlight .p-button-icon-right {
		color: $tt-text-color-active;
	}

	.p-togglebutton.p-button.p-highlight:hover {
		background: $tt-background-color-hover;
		border-color: $tt-border-color-hover;
		color: $tt-text-color-hover;
	}

	.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
	.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
		color: $tt-text-color-hover;
	}

	p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
		border-color: $tt-border-color-error;
	}

	/* ProgressBar */
	.p-progressbar {
		&.p-progressbar-determinate {
			.p-progressbar-value {
				background: #8ec5fc;
				border: 0 none;
			}

			.p-progressbar .p-progressbar-label {
				color: #222;
			}
		}

		&.p-progressbar-indeterminate {
			background: #8ec5fc;

			.p-progressbar-value {
				background-color: $tt-background-color-hover;
				border: 0 none;
			}
		}
	}

	/* Button */
	.p-button {
		align-items: center;
		background: $tt-button-icon-background-color;
		border: 1px solid $tt-border-color;
		border-radius: $tt-border-radius;
		box-shadow: $tt-button-box-shadow;
		color: $tt-menu-disclosure-icon-color;
		display: inline-flex;
		justify-content: center;
		padding: 0.5rem 1rem;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}

	.p-button:not(:disabled):hover {
		background: $tt-color-hover;
		color: $tt-text-color-hover;
		border-color: $tt-border-color-hover;
	}

	.p-button.p-button-outlined {
		background-color: transparent;
		border: 1px solid;
		color: $tt-text-color;
	}

	.p-button.p-button-outlined:enabled:hover {
		background: $tt-color-hover;
		border: 1px solid;
		color: $tt-text-color;
	}

	.p-button.p-button-outlined:enabled:active {
		background: $tt-background-color-active;
		border: 1px solid;
		color: $tt-text-color;
	}

	.p-button.p-button-outlined.p-button-plain {
		border-color: $tt-border-color;
		color: $tt-text-color;
	}

	.p-button.p-button-outlined.p-button-plain:enabled:hover {
		background: $tt-background-color-hover;
		color: $tt-text-color;
	}

	.p-button.p-button-outlined.p-button-plain:enabled:active {
		background: #dbdbdb;
		color: $tt-text-color;
	}

	.p-button.p-button-text {
		background-color: transparent;
		border-color: transparent;
		color: $tt-text-color;
	}

	.p-button.p-button-text:not(:disabled):hover {
		background: $tt-background-color-hover;
		border-color: transparent;
		color: $tt-text-color;
	}

	.p-button.p-button-text:not(:disabled):active {
		background: $tt-background-color-active;
		border-color: transparent;
		color: $tt-text-color;
	}

	.p-button.p-button-text.p-button-plain {
		color: $tt-text-color;
	}

	.p-button.p-button-text.p-button-plain:not(:disabled):hover {
		background: $tt-background-color-hover;
		color: $tt-text-color;
	}

	.p-button.p-button-text.p-button-plain:not(:disabled):active {
		background: $tt-background-color-active;
		color: $tt-text-color;
	}

	.p-button .p-button-icon-left {
		margin-right: 0.5rem;
	}

	.p-button .p-button-icon-right {
		margin-left: 0.5rem;
	}

	.p-button .p-button-icon-bottom {
		margin-top: 0.5rem;
	}

	.p-button .p-button-icon-top {
		margin-bottom: 0.5rem;
	}

	.p-button .p-badge {
		background-color: $tt-color-white;
		color: $tt-badge-color-zero;
		height: 1rem;
		line-height: 1rem;
		margin-left: 0.5rem;
		min-width: 1rem;
	}

	.p-button.p-button-raised {
		box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
	}

	.p-button.p-button-rounded {
		border-radius: 2rem;
	}

	.p-button.p-button-icon-only {
		padding: 0.5rem 0;
		width: $tt-button-icon-only-size;
	}

	.p-button.p-button-icon-only .p-button-icon-left,
	.p-button.p-button-icon-only .p-button-icon-right {
		margin: 0;
	}

	.p-button.p-button-icon-only.p-button-rounded {
		border-radius: 50%;
		height: $tt-button-icon-only-size;
		width: $tt-button-icon-only-size;
	}

	.p-button.p-button-sm {
		font-size: 0.625rem;
		padding: 0.375375rem 0.875rem;
	}

	.p-button.p-button-sm .p-button-icon {
		font-size: 0.625rem;
	}

	.p-button.p-button-lg {
		font-size: 1rem;
		padding: 0.53625rem 1.25rem;
	}

	.p-button.p-button-lg .p-button-icon {
		font-size: 1rem;
	}

	.p-fluid .p-button {
		width: 100%;
	}

	.p-fluid .p-button-icon-only {
		width: $tt-button-icon-only-size;
	}

	.p-fluid .p-buttonset {
		display: flex;
	}

	.p-fluid .p-buttonset .p-button {
		flex: 1;
	}

	.p-button.p-button-secondary,
	.p-buttonset.p-button-secondary > .p-button,
	.p-splitbutton.p-button-secondary > .p-button {
		background: $tt-color-secondary;
		border: 1px solid color.adjust($tt-color-secondary, $lightness: -50%);
		color: $tt-color-white;
	}

	.p-button.p-button-secondary:enabled:hover,
	.p-buttonset.p-button-secondary > .p-button:enabled:hover,
	.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
		background: color.adjust($tt-color-secondary, $lightness: -50%);
		border-color: color.adjust($tt-color-secondary, $lightness: -50%);
		color: $tt-color-white;
	}

	.p-button.p-button-secondary:enabled:focus,
	.p-buttonset.p-button-secondary > .p-button:enabled:focus,
	.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
		box-shadow: 0 0 0 0.2rem color.adjust($tt-color-secondary, $lightness: 50%);
	}

	.p-button.p-button-secondary:enabled:active,
	.p-buttonset.p-button-secondary > .p-button:enabled:active,
	.p-splitbutton.p-button-secondary > .p-button:enabled:active {
		background: color.adjust($tt-color-secondary, $lightness: -50%);
		border-color: color.adjust($tt-color-secondary, $lightness: -50%);
		color: $tt-color-white;
	}

	.p-button.p-button-secondary.p-button-outlined,
	.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
	.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
		background-color: transparent;
		border: 1px solid;
		color: $tt-color-secondary;
	}

	.p-button.p-button-secondary.p-button-outlined:enabled:hover,
	.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
	.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
		background: color.adjust($tt-color-secondary, $lightness: 80%);
		border: 1px solid;
		color: $tt-color-secondary;
	}

	.p-button.p-button-secondary.p-button-outlined:enabled:active,
	.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
	.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
		background: color.adjust($tt-color-secondary, $lightness: 80%);
		border: 1px solid;
		color: $tt-color-secondary;
	}

	.p-button.p-button-secondary.p-button-text,
	.p-buttonset.p-button-secondary > .p-button.p-button-text,
	.p-splitbutton.p-button-secondary > .p-button.p-button-text {
		background-color: transparent;
		border-color: transparent;
		color: $tt-color-secondary;
	}

	.p-button.p-button-secondary.p-button-text:enabled:hover,
	.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
	.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
		background: color.adjust($tt-color-secondary, $lightness: 80%);
		border-color: transparent;
		color: $tt-color-secondary;
	}

	.p-button.p-button-secondary.p-button-text:enabled:active,
	.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
	.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
		background: color.adjust($tt-color-secondary, $lightness: 80%);
		border-color: transparent;
		color: $tt-color-secondary;
	}

	.p-button.p-button-info,
	.p-buttonset.p-button-info > .p-button,
	.p-splitbutton.p-button-info > .p-button {
		background: $tt-color-info;
		border: 1px solid $tt-button-info-border-color;
		color: $tt-color-white;
	}

	.p-button.p-button-info:enabled:hover,
	.p-buttonset.p-button-info > .p-button:enabled:hover,
	.p-splitbutton.p-button-info > .p-button:enabled:hover {
		background: $tt-button-info-background-color-hover;
		border-color: $tt-button-info-border-color;
		color: $tt-color-white;
	}

	.p-button.p-button-info:enabled:focus,
	.p-buttonset.p-button-info > .p-button:enabled:focus,
	.p-splitbutton.p-button-info > .p-button:enabled:focus {
		box-shadow: 0 0 0 0.2rem color.adjust($tt-color-info, $lightness: 50%);
	}

	.p-button.p-button-info:enabled:active,
	.p-buttonset.p-button-info > .p-button:enabled:active,
	.p-splitbutton.p-button-info > .p-button:enabled:active {
		background: $tt-button-info-background-color-hover;
		border-color: $tt-button-info-border-color;
		color: $tt-color-white;
	}

	.p-button.p-button-info.p-button-outlined,
	.p-buttonset.p-button-info > .p-button.p-button-outlined,
	.p-splitbutton.p-button-info > .p-button.p-button-outlined {
		background-color: transparent;
		border: 1px solid;
		color: $tt-color-info;
	}

	.p-button.p-button-info.p-button-outlined:enabled:hover,
	.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
	.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
		background: color.adjust($tt-color-info, $lightness: 80%);
		border: 1px solid;
		color: $tt-color-info;
	}

	.p-button.p-button-info.p-button-outlined:enabled:active,
	.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
	.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
		background: color.adjust($tt-color-info, $lightness: 80%);
		border: 1px solid;
		color: $tt-color-info;
	}

	.p-button.p-button-info.p-button-text,
	.p-buttonset.p-button-info > .p-button.p-button-text,
	.p-splitbutton.p-button-info > .p-button.p-button-text {
		background-color: transparent;
		border-color: transparent;
		color: $tt-color-info;
	}

	.p-button.p-button-info.p-button-text:enabled:hover,
	.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
	.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
		background: $tt-color-hover;
		border-color: transparent;
		color: $tt-color-info;
	}

	.p-button.p-button-info.p-button-text:enabled:active,
	.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
	.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
		background: $tt-color-hover;
		border-color: transparent;
		color: $tt-color-info;
	}

	.p-button.p-button-success,
	.p-buttonset.p-button-success > .p-button,
	.p-splitbutton.p-button-success > .p-button {
		background: $tt-color-success;
		border: 1px solid color.adjust($tt-color-success, $lightness: -50%);
		color: $tt-color-white;
	}

	.p-button.p-button-success:enabled:hover,
	.p-buttonset.p-button-success > .p-button:enabled:hover,
	.p-splitbutton.p-button-success > .p-button:enabled:hover {
		background: color.adjust($tt-color-success, $lightness: -50%);
		border-color: color.adjust($tt-color-success, $lightness: -50%);
		color: $tt-color-white;
	}

	.p-button.p-button-success:enabled:focus,
	.p-buttonset.p-button-success > .p-button:enabled:focus,
	.p-splitbutton.p-button-success > .p-button:enabled:focus {
		box-shadow: $tt-button-box-shadow;
	}

	.p-button.p-button-success:enabled:active,
	.p-buttonset.p-button-success > .p-button:enabled:active,
	.p-splitbutton.p-button-success > .p-button:enabled:active {
		background: color.adjust($tt-color-success, $lightness: -50%);
		border-color: color.adjust($tt-color-success, $lightness: -50%);
		color: $tt-color-white;
	}

	.p-button.p-button-success.p-button-outlined,
	.p-buttonset.p-button-success > .p-button.p-button-outlined,
	.p-splitbutton.p-button-success > .p-button.p-button-outlined {
		background-color: transparent;
		border: 1px solid;
		color: $tt-color-success;
	}

	.p-button.p-button-success.p-button-outlined:enabled:hover,
	.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
	.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
		background: $tt-color-hover;
		border: 1px solid;
		color: $tt-color-success;
	}

	.p-button.p-button-success.p-button-outlined:enabled:active,
	.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
	.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
		background: $tt-color-hover;
		border: 1px solid;
		color: $tt-color-success;
	}

	.p-button.p-button-success.p-button-text,
	.p-buttonset.p-button-success > .p-button.p-button-text,
	.p-splitbutton.p-button-success > .p-button.p-button-text {
		background-color: transparent;
		border-color: transparent;
		color: $tt-color-success;
	}

	.p-button.p-button-success.p-button-text:enabled:hover,
	.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
	.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
		background: $tt-color-hover;
		border-color: transparent;
		color: $tt-color-success;
	}

	.p-button.p-button-success.p-button-text:enabled:active,
	.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
	.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
		background: $tt-color-hover;
		border-color: transparent;
		color: $tt-color-success;
	}

	.p-button.p-button-warning,
	.p-buttonset.p-button-warning > .p-button,
	.p-splitbutton.p-button-warning > .p-button {
		background: $tt-color-warning;
		border: 1px solid color.adjust($tt-color-warning, $lightness: -20%);
		color: $tt-color-white;
	}

	.p-button.p-button-warning:enabled:hover,
	.p-buttonset.p-button-warning > .p-button:enabled:hover,
	.p-splitbutton.p-button-warning > .p-button:enabled:hover {
		background: $tt-color-warning;
		border-color: color.adjust($tt-color-warning, $lightness: -20%);
		color: $tt-color-white;
	}

	.p-button.p-button-warning:enabled:focus,
	.p-buttonset.p-button-warning > .p-button:enabled:focus,
	.p-splitbutton.p-button-warning > .p-button:enabled:focus {
		box-shadow: 0 0 0 0.2rem color.adjust($tt-color-warning, $lightness: 80%);
	}

	.p-button.p-button-warning:enabled:active,
	.p-buttonset.p-button-warning > .p-button:enabled:active,
	.p-splitbutton.p-button-warning > .p-button:enabled:active {
		background: $tt-color-warning;
		border-color: color.adjust($tt-color-warning, $lightness: -20%);
		color: $tt-color-white;
	}

	.p-button.p-button-warning.p-button-outlined,
	.p-buttonset.p-button-warning > .p-button.p-button-outlined,
	.p-splitbutton.p-button-warning > .p-button.p-button-outlined {
		background-color: transparent;
		border: 1px solid;
		color: $tt-color-warning;
	}

	.p-button.p-button-warning.p-button-outlined:enabled:hover,
	.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
	.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
		background: color.adjust($tt-color-warning, $lightness: 80%);
		border: 1px solid;
		color: $tt-color-warning;
	}

	.p-button.p-button-warning.p-button-outlined:enabled:active,
	.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
	.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
		background: color.adjust($tt-color-warning, $lightness: 80%);
		border: 1px solid;
		color: $tt-color-warning;
	}

	.p-button.p-button-warning.p-button-text,
	.p-buttonset.p-button-warning > .p-button.p-button-text,
	.p-splitbutton.p-button-warning > .p-button.p-button-text {
		background-color: transparent;
		border-color: transparent;
		color: $tt-color-warning;
	}

	.p-button.p-button-warning.p-button-text:enabled:hover,
	.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
	.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
		background: color.adjust($tt-color-warning, $lightness: 80%);
		border-color: transparent;
		color: $tt-color-warning;
	}

	.p-button.p-button-warning.p-button-text:enabled:active,
	.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
	.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
		background: color.adjust($tt-color-warning, $lightness: 80%);
		border-color: transparent;
		color: $tt-color-warning;
	}

	.p-button.p-button-help,
	.p-buttonset.p-button-help > .p-button,
	.p-splitbutton.p-button-help > .p-button {
		background: $tt-color-help;
		border: 1px solid color.adjust($tt-color-help, $lightness: -20%);
		color: $tt-color-white;
	}

	.p-button.p-button-help:enabled:hover,
	.p-buttonset.p-button-help > .p-button:enabled:hover,
	.p-splitbutton.p-button-help > .p-button:enabled:hover {
		background: color.adjust($tt-color-help, $lightness: 80%);
		border-color: color.adjust($tt-color-help, $lightness: 80%);
		color: $tt-color-white;
	}

	.p-button.p-button-help:enabled:focus,
	.p-buttonset.p-button-help > .p-button:enabled:focus,
	.p-splitbutton.p-button-help > .p-button:enabled:focus {
		box-shadow: 0 0 0 0.2rem color.adjust($tt-color-help, $lightness: 80%);
	}

	.p-button.p-button-help:enabled:active,
	.p-buttonset.p-button-help > .p-button:enabled:active,
	.p-splitbutton.p-button-help > .p-button:enabled:active {
		background: color.adjust($tt-color-help, $lightness: 80%);
		border-color: color.adjust($tt-color-help, $lightness: 80%);
		color: $tt-color-white;
	}

	.p-button.p-button-help.p-button-outlined,
	.p-buttonset.p-button-help > .p-button.p-button-outlined,
	.p-splitbutton.p-button-help > .p-button.p-button-outlined {
		background-color: transparent;
		border: 1px solid;
		color: $tt-color-help;
	}

	.p-button.p-button-help.p-button-outlined:enabled:hover,
	.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
	.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
		background: color.adjust($tt-color-help, $lightness: 80%);
		border: 1px solid;
		color: $tt-color-help;
	}

	.p-button.p-button-help.p-button-outlined:enabled:active,
	.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
	.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
		background: color.adjust($tt-color-help, $lightness: 80%);
		border: 1px solid;
		color: $tt-color-help;
	}

	.p-button.p-button-help.p-button-text,
	.p-buttonset.p-button-help > .p-button.p-button-text,
	.p-splitbutton.p-button-help > .p-button.p-button-text {
		background-color: transparent;
		border-color: transparent;
		color: $tt-color-help;
	}

	.p-button.p-button-help.p-button-text:enabled:hover,
	.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
	.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
		background: color.adjust($tt-color-help, $lightness: 80%);
		border-color: transparent;
		color: $tt-color-help;
	}

	.p-button.p-button-help.p-button-text:enabled:active,
	.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
	.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
		background: color.adjust($tt-color-help, $lightness: 80%);
		border-color: transparent;
		color: $tt-color-help;
	}

	.p-button.p-button-danger,
	.p-buttonset.p-button-danger > .p-button,
	.p-splitbutton.p-button-danger > .p-button {
		background: $tt-color-danger;
		border: 1px solid color.adjust($tt-color-danger, $lightness: -20%);
		color: $tt-color-white;
	}

	.p-button.p-button-danger:enabled:hover,
	.p-buttonset.p-button-danger > .p-button:enabled:hover,
	.p-splitbutton.p-button-danger > .p-button:enabled:hover {
		background: color.adjust($tt-color-danger, $lightness: -20%);
		border-color: color.adjust($tt-color-danger, $lightness: -20%);
		color: $tt-color-white;
	}

	.p-button.p-button-danger:enabled:focus,
	.p-buttonset.p-button-danger > .p-button:enabled:focus,
	.p-splitbutton.p-button-danger > .p-button:enabled:focus {
		box-shadow: 0 0 0 0.2rem color.adjust($tt-color-danger, $lightness: 5%);
	}

	.p-button.p-button-danger:enabled:active,
	.p-buttonset.p-button-danger > .p-button:enabled:active,
	.p-splitbutton.p-button-danger > .p-button:enabled:active {
		background: color.adjust($tt-color-danger, $lightness: -20%);
		border-color: color.adjust($tt-color-danger, $lightness: -20%);
		color: $tt-color-white;
	}

	.p-button.p-button-danger.p-button-outlined,
	.p-buttonset.p-button-danger > .p-button.p-button-outlined,
	.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
		background-color: transparent;
		border: 1px solid;
		color: $tt-color-danger;
	}

	.p-button.p-button-danger.p-button-outlined:enabled:hover,
	.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
	.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
		background: color.adjust($tt-color-danger, $lightness: 80%);
		border: 1px solid;
		color: $tt-color-danger;
	}

	.p-button.p-button-danger.p-button-outlined:enabled:active,
	.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
	.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
		background: color.adjust($tt-color-danger, $lightness: 80%);
		border: 1px solid;
		color: $tt-color-danger;
	}

	.p-button.p-button-danger.p-button-text,
	.p-buttonset.p-button-danger > .p-button.p-button-text,
	.p-splitbutton.p-button-danger > .p-button.p-button-text {
		background-color: transparent;
		border-color: transparent;
		color: $tt-color-danger;
	}

	.p-button.p-button-danger.p-button-text:enabled:hover,
	.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
	.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
		background: color.adjust($tt-color-danger, $lightness: 80%);
		border-color: transparent;
		color: $tt-color-danger;
	}

	.p-button.p-button-danger.p-button-text:enabled:active,
	.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
	.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
		background: color.adjust($tt-color-danger, $lightness: 80%);
		border-color: transparent;
		color: $tt-color-danger;
	}

	.p-button.p-button-link {
		background: transparent;
		border: transparent;
		color: $tt-text-color;
	}

	.p-button.p-button-link:enabled:hover {
		background: transparent;
		border-color: transparent;
		color: $tt-text-color-active;
	}

	.p-button.p-button-link:enabled:hover .p-button-label {
		text-decoration: underline;
	}

	.p-button.p-button-link:enabled:focus {
		background: transparent;
		border-color: transparent;
		box-shadow: 0 0 0 0.2rem color.adjust($tt-border-color, $lightness: 80%);
	}

	.p-button.p-button-link:enabled:active {
		background: transparent;
		border-color: transparent;
		color: $tt-text-color-active;
	}

	/* FileUpload */
	.p-fileupload-choose:not(.p-disabled):hover,
	.p-fileupload-choose.p-focus {
		background: #1f89ce;
		border: 1px solid #1f89ce;
		color: $tt-color-white;
		outline: 0 none;
	}

	.p-fileupload-choose:not(.p-disabled):active {
		background: #186ba0;
		border: 1px solid #156090;
		color: $tt-color-white;
	}

	/* Checkbox and Radio */
	.p-chkbox-box.p-highlight,
	.p-radiobutton-box.p-highlight {
		background: #186ba0;
		border: 1px solid #156090;
		color: $tt-color-white;
	}

	.p-chkbox-box.p-focus,
	.p-radiobutton-box.p-focus {
		box-shadow: 0 0 5px $tt-background-color-focus;
	}

	.p-chkbox-box.p-focus.p-highlight {
		background: $tt-background-color-active;
	}

	.p-checkbox {
		height: 18px;
		width: 18px;
	}

	.p-checkbox .p-checkbox-box {
		background: $tt-color-white;
		border: 1px solid $tt-border-color;
		border-radius: $tt-border-radius;
		color: $tt-header-text-color;
		height: 18px;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		width: 18px;
	}

	.p-checkbox .p-checkbox-box .p-checkbox-icon {
		color: $tt-color-white;
		font-size: 0.875rem;
		transition-duration: 0.2s;
	}

	.p-checkbox .p-checkbox-box.p-highlight {
		background: #186ba0;
		border-color: #156090;
	}

	/* Inputs */
	.p-inputtext {
		background: $tt-color-white;
		border: 1px solid $tt-border-color;
		color: $tt-text-color;
		transition: 0.2s;

		label {
			line-height: 1.5rem;
			padding: 0.375rem 0.5rem;
		}
	}

	.p-placeholder {
		color: $text-muted;;
	}

	.p-inputtext.p-focus,
	.p-inputtext:focus {
		box-shadow: none;
	}

	/* InputSwitch */
	.p-inputswitch-on {
		background: $tt-background-color-focus !important;
		color: $tt-color-white !important;
	}

	.p-inputswitch.p-inputswitch-focus {
		.p-inputswitch-slider {
			box-shadow: 0 0 5px $tt-color-blue-300;
		}
	}

	/* InputText */
	.p-inputtext {
		font-family: var(--font-family);
		font-feature-settings: var(--font-feature-settings, normal);
		font-size: 1rem;
		color: $tt-input-text-color;
		background: $tt-input-background-color;
		padding: 0.5rem;
		border: 1px solid $tt-border-color;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		appearance: none;
		border-radius: $tt-border-radius;

		&:read-only:not(.p-autocomplete-multiple-container, .p-chips-multiple-container, .p-dropdown-label) { // Match read-only to disabled state
			background: $tt-input-background-color-disabled;
		}
	}

	.p-inputtext:enabled:hover {
		border-color: $tt-input-text-border-color-hover;
	}

	.p-inputtext:enabled:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #e4e9ec;
		border-color: $tt-input-text-border-color-focus;
	}

	.p-inputtext.ng-dirty.ng-invalid {
		border-color: $tt-input-border-color-error;
	}

	.p-inputtext.p-inputtext-sm {
		font-size: 0.875rem;
		padding: 0.375rem;
	}

	.p-inputtext.p-inputtext-lg {
		font-size: 1.25rem;
		padding: 0.875rem;
	}

	.p-float-label > label {
		left: 0.5rem;
		color: $tt-color-secondary;
		transition-duration: 0.2s;
	}

	.p-float-label > .ng-invalid.ng-dirty + label {
		color: $tt-text-color-error;
	}

	.p-input-icon-left > .p-icon-wrapper.p-icon,
	.p-input-icon-left > i:first-of-type {
		left: 0.75rem;
		color: $tt-color-secondary;
	}

	.p-input-icon-left > .p-inputtext {
		padding-left: 1.875rem;
	}

	.p-input-icon-left.p-float-label > label {
		left: 1.875rem;
	}

	.p-input-icon-right > .p-icon-wrapper,
	.p-input-icon-right > i:last-of-type {
		right: 0.75rem;
		color: $tt-color-secondary;
	}

	.p-input-icon-right > .p-inputtext {
		padding-right: 1.875rem;
	}

	::-webkit-input-placeholder {
		color: $tt-text-color-muted;
	}

	:-moz-placeholder {
		color: $tt-text-color-muted;
	}

	::-moz-placeholder {
		color: $tt-text-color-muted;
	}

	:-ms-input-placeholder {
		color: $tt-text-color-muted;
	}

	.p-input-filled .p-inputtext {
		background-color: #f4f4f4;
	}

	.p-input-filled .p-inputtext:enabled:hover {
		background-color: #f4f4f4;
	}

	.p-input-filled .p-inputtext:enabled:focus {
		background-color: #f4f4f4;
	}

	.p-inputtext-sm .p-inputtext {
		font-size: 0.875rem;
		padding: 0.375375rem;
	}

	.p-inputtext-lg .p-inputtext {
		font-size: 1.25rem;
		padding: 0.875rem;
	}

	/* InputGroup */
	.p-inputgroup-addon {
		background-color: color.adjust($tt-border-color, $lightness: 10%);
		border-bottom: 1px solid $tt-border-color;
		border-color: $tt-border-color;
		border-left: 1px solid $tt-border-color;
		border-top: 1px solid $tt-border-color;
		color: $tt-input-group-text-color;
		min-width: 2.357rem;
		padding: 0.5rem;

		&:last-child {
			border-right: 1px solid $tt-border-color;
		}
	}

	.p-inputgroup > .p-component,
	.p-inputgroup > .p-inputwrapper > .p-inputtext,
	.p-inputgroup > .p-float-label > .p-component {
		border-radius: 0;
		margin: 0;
	}

	.p-inputgroup > .p-component + .p-inputgroup-addon,
	.p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
	.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
		border-left: 0 none;
	}

	.p-inputgroup > .p-component:focus,
	.p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
	.p-inputgroup > .p-float-label > .p-component:focus {
		z-index: 1;
	}

	.p-inputgroup > .p-component:focus ~ label,
	.p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
	.p-inputgroup > .p-float-label > .p-component:focus ~ label {
		z-index: 1;
	}

	.p-inputgroup-addon:first-child,
	.p-inputgroup button:first-child,
	.p-inputgroup input:first-child,
	.p-inputgroup > .p-inputwrapper:first-child > .p-component,
	.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
		border-bottom-left-radius: $tt-border-radius;
		border-top-left-radius: $tt-border-radius;
	}

	.p-inputgroup .p-float-label:first-child input {
		border-bottom-left-radius: $tt-border-radius;
		border-top-left-radius: $tt-border-radius;
	}

	.p-inputgroup-addon:last-child,
	.p-inputgroup button:last-child,
	.p-inputgroup input:last-child,
	.p-inputgroup > .p-inputwrapper:last-child > .p-component,
	.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
		border-bottom-right-radius: $tt-border-radius;
		border-top-right-radius: $tt-border-radius;
	}

	.p-inputgroup .p-float-label:last-child input {
		border-bottom-right-radius: $tt-border-radius;
		border-top-right-radius: $tt-border-radius;
	}

	.p-fluid .p-inputgroup .p-button {
		width: auto;
	}

	.p-fluid .p-inputgroup .p-button.p-button-icon-only {
		width: $tt-button-icon-only-size;
	}

	/* Paginator */
	.p-paginator .p-paginator-page.p-highlight {
		background: $tt-background-color-active;
		border-color: $tt-border-color-active;
		color: $tt-color-white;
	}

	/* Table */
	.p-datatable table {
		min-width: 99.9% !important; // prevents scrolling on mobile viewports
	}

	.p-datatable {
		.p-rowgroup-header a {
			color: $tt-header-text-color;
		}

		.p-sortable-column:not(.p-highlight):hover {
			background-color: $tt-header-background-color;
			color: $tt-color-black;
			outline: none;
			padding: 0.438rem;
		}

		.p-row-toggler {
			color: $tt-content-text-color;
		}

		tbody.p-datatable-hoverable-rows {
			> tr.p-component-content:not(.p-highlight):hover {
				background-color: $tt-color-hover;
				color: $tt-color-black;
				cursor: pointer;
			}
		}
	}

	.p-datatable .p-datatable-header {
		background: $tt-header-background-color;
		border: 1px solid $tt-header-border-color;
		color: $tt-header-text-color;
		padding: $tt-header-padding;
	}

	.p-datatable .p-datatable-thead > tr > th {
		background: $tt-header-background-color;
		border: 1px solid $tt-header-border-color;
		border-top: none;
		color: $tt-header-text-color;
		height: $tt-row-height;
		padding: $tt-header-padding !important;
		text-align: left;
		transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}

	.p-datatable .p-datatable-tbody > tr {
		background: $tt-content-background-color;
		color: $tt-header-text-color;
		height: $tt-row-height;
	}

	.p-datatable .p-datatable-tbody > tr > td {
		border-left: 1px solid $tt-header-border-color;
		padding: $tt-table-row-padding;
		text-align: left;
		word-break: break-word;

		@media (310px <= width <= 767px) {
			border: none;
		}
	}

	.p-datatable .p-datatable-tbody > tr > td:first-child {
		border-left: none;
	}

	.p-datatable .p-datatable-tbody > tr:focus {
		background-color: $tt-color-hover;
	}

	.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
	.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
	.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
	.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
		background: transparent;
		border-color: transparent;
		color: $tt-header-text-color;
	}

	.p-datatable .p-datatable-tbody > tr.p-highlight {
		background: $tt-color-active;
		color: $tt-header-text-color;

		&:active {
			outline: none;
		}

		&:focus {
			outline: none;
		}
	}

	.p-datatable .p-datatable-tbody > tr.p-highlight:active {
		outline: none;
	}

	.p-datatable .p-datatable-tbody > tr {
		border-bottom: 1px solid $tt-border-color;
		border-left: 1px solid $tt-border-color;
		border-right: 1px solid $tt-border-color;
		outline: none;
	}

	.p-datatable .p-datatable-tbody > tr:active {
		outline: none;
	}

	.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
		background: $tt-header-background-color-hover;
		color: $tt-header-text-color;
	}

	.p-datatable-scrollable-view {
		div {
			overflow: auto !important;
			padding: 0 !important;
		}
	}

	/* TreeTable */
	.p-treetable {
		.p-treetable-thead > tr > th,
		.p-treetable-tfoot > tr > td {
			background-color: #ebedf0;
			border-color: $tt-border-color;
		}

		.p-sortable-column:not(.p-highlight):hover {
			background-color: #d3d5d8;
			border-color: $tt-border-color;
		}
	}

	/* Panel */
	.p-panel {
		.p-panel-header {
			background: $tt-header-background-color;
			border-bottom: 1px solid $tt-header-border-color;
			min-height: $tt-row-height;
			padding: $tt-header-padding;
		}

		.p-panel-content {
			background: $tt-content-background-color;
			border-top: 0 none;
			padding: $tt-content-padding;
		}
	}

	.p-panel .p-panel-titlebar {
		box-sizing: border-box;
		padding: 0.5rem 0.75rem;

		.p-panel-title {
			display: inline-block;
			text-transform: uppercase;
		}

		button {
			height: 1.625rem;
			padding: 0;
			width: 1.625rem;

			i {
				font-size: 0.875rem;
				line-height: 0.75rem;
				margin: 0;
				padding: 0;
			}

			span {
				font-size: 0.875rem;
				line-height: 0.75rem;
				margin: 0;
				padding: 0;
			}
		}
	}

	.p-panel .p-panel-icons-end {
		order: 2;
		margin-left: auto;
	}

	.p-multiselect {
		.p-multiselect-label {
			background-color: $tt-color-white;
		}
	}

	.p-dropdown.p-focus,
	.p-multiselect.p-focus {
		box-shadow: 0 0 5px $tt-input-text-border-color-focus;
	}

	/* Inline Message */
	.p-inline-message {
		padding: 0.5rem;
		margin: 0;
		border-radius: 2px;
	}

	.p-inline-message.p-inline-message-info {
		background: color.scale($tt-color-info, $lightness: 66%);
		border: 1px solid color.scale($tt-color-info, $lightness: 66%);
		color: color.scale($tt-color-info, $lightness: -50%);
	}

	.p-inline-message.p-inline-message-info .p-inline-message-icon {
		color: color.scale($tt-color-info, $lightness: -50%);
	}

	.p-inline-message.p-inline-message-success {
		background: color.scale($tt-color-success, $lightness: 66%);
		border: 1px solid color.scale($tt-color-success, $lightness: 66%);
		color: color.scale($tt-color-success, $lightness: -50%);
	}

	.p-inline-message.p-inline-message-success .p-inline-message-icon {
		color: color.scale($tt-color-success, $lightness: -50%);
	}

	.p-inline-message.p-inline-message-warn {
		background: color.scale($tt-color-warning, $lightness: 66%);
		border: 1px solid color.scale($tt-color-warning, $lightness: 66%);
		color: color.scale($tt-color-warning, $lightness: -50%);
	}

	.p-inline-message.p-inline-message-warn .p-inline-message-icon {
		color: color.scale($tt-color-warning, $lightness: -50%)
	}

	.p-inline-message.p-inline-message-error {
		background: color.scale($tt-color-danger, $lightness: 66%);
		border: 1px solid color.scale($tt-color-danger, $lightness: 66%);
		color: color.scale($tt-color-danger, $lightness: -50%);
	}

	.p-inline-message.p-inline-message-error .p-inline-message-icon {
		color: color.scale($tt-color-danger, $lightness: -50%);
	}

	.p-inline-message .p-inline-message-icon {
		font-size: 0.875rem;
		margin-right: 0.5rem;
	}

	.p-inline-message .p-icon {
		width: .875rem;
		height: .875rem;
	}

	.p-inline-message .p-inline-message-text {
		font-size: 0.875rem;
	}

	.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
		margin-right: 0;
	}

	/* Messages */
	.p-message {
		margin: 1rem 0;
		border-radius: 2px;
	}

	.p-message .p-message-wrapper {
		padding: 1rem;
		display: block;
	}

	.p-message .p-message-close {
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		background: transparent;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	}

	.p-message .p-message-close:hover {
		background: rgba(255 255 255 / 30%);
	}

	.p-message .p-message-close:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #e4e9ec;
	}

	.p-message.p-message-info {
		background: color.scale($tt-color-info, $lightness: 66%);
		border: 1px solid color.scale($tt-color-info, $lightness: 66%);
		color: color.scale($tt-color-info, $lightness: -50%);
	}

	.p-message.p-message-info .p-message-icon {
		color: color.scale($tt-color-info, $lightness: -50%);
	}

	.p-message.p-message-info .p-message-close {
		color: color.scale($tt-color-info, $lightness: -50%);
	}

	.p-message.p-message-success {
		background: color.scale($tt-color-success, $lightness: 66%);
		border: 1px solid color.scale($tt-color-success, $lightness: 66%);
		color: color.scale($tt-color-success, $lightness: -50%);
	}

	.p-message.p-message-success .p-message-icon {
		color: color.scale($tt-color-success, $lightness: -50%);
	}

	.p-message.p-message-success .p-message-close {
		color: color.scale($tt-color-success, $lightness: -50%);
	}

	.p-message.p-message-warn {
		background: color.scale($tt-color-warning, $lightness: 66%);
		border: 1px solid color.scale($tt-color-warning, $lightness: 66%);
		color: color.scale($tt-color-warning, $lightness: -50%);
	}

	.p-message.p-message-warn .p-message-icon {
		color: color.scale($tt-color-warning, $lightness: -50%);
	}

	.p-message.p-message-warn .p-message-close {
		color: color.scale($tt-color-warning, $lightness: -50%);
	}

	.p-message.p-message-error {
		background: color.scale($tt-color-danger, $lightness: 66%);
		border: 1px solid color.scale($tt-color-danger, $lightness: 66%);
		color: color.scale($tt-color-danger, $lightness: -50%);
	}

	.p-message.p-message-error .p-message-icon {
		color: color.scale($tt-color-danger, $lightness: -50%);
	}

	.p-message.p-message-error .p-message-close {
		color: color.scale($tt-color-danger, $lightness: -50%);
	}

	.p-message .p-message-text {
		font-size: 1rem;
		font-weight: normal;
	}

	.p-message .p-message-icon {
		font-size: 1.5rem;
		margin-right: 0.5rem;
	}

	.p-message .p-icon {
		width: 1.5rem;
		height: 1.5rem;
	}

	.p-message .p-message-summary {
		font-weight: 700;
	}

	.p-message .p-message-detail {
		margin-left: 0.5rem;
	}

	/* Toast */
	.p-toast {
		box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%);
		width: 16rem !important;

		.p-toast-message {
			background-color: $tt-color-white;

			.p-toast-message-content {
				padding: 1rem;

				.p-toast-summary {
					font-size: $tt-font-size;
					font-weight: 500;
				}

				.p-toast-message-icon {
					font-size: 0.875rem;
					font-weight: 900;
					margin: 0.1rem 0.5rem 0 0;
				}

				.p-toast-detail {
					font-size: 0.875rem;
					margin: 0.5rem 0 0.5rem -1.375rem;
					overflow-wrap: anywhere;
				}

				.p-toast-icon-close {
					width: 2rem;
					border-radius: 50%;
					background: transparent;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					color: $tt-color-gray-800;
					text-shadow: 0.125rem 0.125rem 0.125rem rgb(0 0 0 / 20%);

					&:hover {
						filter: brightness(50%);
					}

					&:active {
						text-shadow: none;
					}
				}
			}

			&.p-toast-message-info {
				border-color: $tt-color-info;
				color: $tt-color-info;
			}

			&.p-toast-message-error {
				border-color: $tt-color-danger;
				color: $tt-color-danger;
			}

			&.p-toast-message-warn {
				border-color: $tt-color-warning;
				color: $tt-color-warning;
			}

			&.p-toast-message-success {
				border-color: $tt-color-success;
				color: $tt-color-success;
			}
		}
	}

	/* Badge */
	.p-badge {
		background: #7b95a3;
		color: white;
		font-size: 0.75rem;
		font-weight: 700;
		height: 1.5rem;
		line-height: 1.5rem;
		min-width: 1.5rem;
	}

	.p-badge.p-badge-secondary {
		background-color: $tt-color-secondary;
		color: $tt-color-white;
	}

	.p-badge.p-badge-success {
		background-color: $tt-color-success;
		color: $tt-text-color;
	}

	.p-badge.p-badge-info {
		background-color: $tt-color-info;
		color: $tt-text-color;
	}

	.p-badge.p-badge-warning {
		background-color: $tt-color-warning;
		color: $tt-text-color;
	}

	.p-badge.p-badge-danger {
		background-color: $tt-color-danger;
		color: $tt-text-color;
	}

	.p-badge.p-badge-lg {
		font-size: 1.125rem;
		height: 2.25rem;
		line-height: 2.25rem;
		min-width: 2.25rem;
	}

	.p-badge.p-badge-xl {
		font-size: 1.5rem;
		height: 3rem;
		line-height: 3rem;
		min-width: 3rem;
	}

	/* Chip */
	.p-chip {
		background-color: #dadada;
		color: $tt-text-color;
		border-radius: $tt-chips-border-radius;
		padding: 0 0.429rem;
	}

	.p-chip .p-chip-text {
		line-height: 1.5;
		margin-top: 0.2145rem;
		margin-bottom: 0.2145rem;
	}

	.p-chip .p-chip-icon {
		margin-right: 0.5rem;
	}

	.p-chip .pi-chip-remove-icon {
		margin-left: 0.5rem;
	}

	.p-chip img {
		width: 1.929rem;
		height: 1.929rem;
		margin-left: -0.429rem;
		margin-right: 0.5rem;
	}

	.p-chip .pi-chip-remove-icon {
		border-radius: 2px;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	}

	.p-chip .pi-chip-remove-icon:focus-visible {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #e4e9ec;
	}

	.p-chip .pi-chip-remove-icon:focus {
		outline: 0 none;
	}

	/* TabMenu */
	.p-tabmenu {
		border: 0 none;

		.p-tabmenu-nav {
			background: none;

			> li {
				&.p-highlight {
					background: $tt-color-white;
					color: #555;
					font-weight: normal;
				}

				&:not(.p-highlight):not(.p-disabled):hover {
					background: #ededf0;
				}
			}
		}
	}

	.p-menubar .p-submenu-list,
	.p-tieredmenu,
	.p-tieredmenu .p-submenu-list,
	.p-slidemenu,
	.p-slidemenu .p-submenu-list,
	.p-contextmenu,
	.p-contextmenu .p-submenu-list,
	.p-megamenu {
		background: $tt-menubar-background-color;
		color: #1b1d1f;
	}

	.p-tieredmenu {
		padding: 0;
		border: 1px solid $tt-border-color;
		width: 12.5rem;

		.p-menuitem-link {
			padding: $tt-table-row-padding;
			border-radius: 0;
			transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
			user-select: none;

			.p-menuitem-icon {
				margin-right: 0.5rem;
			}
		}
	}

	.p-tieredmenu.p-tieredmenu-overlay {
		box-shadow: 0 0 6px 0 rgba(0 0 0 / 16%);
	}

	.p-menu,
	.p-menu .p-menu-child {
		border: 1px solid $tt-border-color;
	}

	.p-menu {
		background: $tt-menu-item-background-color-active;
		color: $tt-menu-item-text-color;
		padding: 0.25rem;
		width: 12.5rem;
	}

	.p-menu .p-menuitem-link {
		color: $tt-menu-item-text-color;
		padding: 0.5rem;
		transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
		user-select: none;
	}

	.p-menu .p-menuitem-link .p-menuitem-text {
		color: $tt-menu-item-text-color;
	}

	.p-menu .p-menuitem-link .p-menuitem-icon {
		margin-right: 0.5rem;
	}

	.p-menu .p-menuitem-link .p-submenu-icon {
		color: $tt-menu-item-text-color;
	}

	.p-menu .p-menuitem-link:not(.p-disabled):hover {
		background: $tt-list-item-background-color-hover;
	}

	.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
		color: $tt-menu-item-text-color-active;
	}

	.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
		color: $tt-menu-item-text-color-active default;
	}

	.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
		color: $tt-menu-item-text-color-active default;
	}

	.p-menu .p-menuitem-link:focus {
		box-shadow: inset 0 0 0 0.2rem #e4e9ec;
		outline: 0 none;
		outline-offset: 0;
	}

	.p-menu.p-menu-overlay {
		background: $tt-menu-overlay-background;
		border: 1px solid $tt-border-color;
		box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
	}

	.p-menu .p-submenu-header {
		background: $tt-menubar-background-color;
		border-top-left-radius: $tt-border-radius;
		border-top-right-radius: $tt-border-radius;
		color: $tt-menu-item-text-color;
		font-weight: 700;
		margin: 0;
		padding: 0.857rem;
	}

	.p-menu .p-menu-separator {
		border-top: 1px solid $tt-border-color;
		margin: 0;
	}

	.p-menu .p-menuitem {
		margin: 0;
	}

	.p-menu .p-menuitem:last-child {
		margin: 0;
	}

	/* Menubar */
	.p-menubar {
		padding: 0.5rem;
		background: $tt-header-background-color;
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-menubar-root-list {
		outline: 0 none;
	}

	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content {
		color: $tt-menu-item-text-color;
		transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
		border-radius: 2px;
	}

	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
		padding: 0.857rem;
		user-select: none;
	}

	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
		color: $tt-menu-item-text-color;
		margin-right: 0.5rem;
	}

	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
		color: $tt-menu-item-text-color;
		margin-left: 0.5rem;
	}

	.p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
		color: $tt-menu-item-text-color;
		background: $tt-list-item-background-color-hover;
	}

	.p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) >
	.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menubar-root-list >
	.p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-menuitem > .p-menuitem-content {
		color: $tt-menu-item-text-color;
		transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
		border-radius: 0;
	}

	.p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
		color: $tt-menu-item-text-color;
		padding: 0.857rem;
		user-select: none;
	}

	.p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
		color: $tt-menu-item-text-color;
		margin-right: 0.5rem;
	}

	.p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
		color: $tt-menu-item-text-color;
		background: $tt-menu-item-background-color-active;
	}

	.p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-menuitem.p-highlight >
	.p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-highlight >
	.p-menuitem-content .p-menuitem-link .p-submenu-icon {
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
		background: $tt-menu-item-background-color-active;
	}

	.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
		color: $tt-menu-item-text-color;
		background: $tt-color-white;
	}

	.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus >
	.p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus >
	.p-menuitem-content .p-menuitem-link .p-submenu-icon {
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
		color: $tt-menu-item-text-color;
		background: $tt-list-item-background-color-hover;
	}

	.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) >
	.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) >
	.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
		color: $tt-menu-item-text-color;
	}

	.p-menubar .p-submenu-list {
		padding: 0;
		background: $tt-color-white;
		border: 1px solid $tt-list-item-background-color-active;
		box-shadow: 0 0 6px 0 rgba(0 0 0 / 16%);
		width: 12.5rem;
		z-index: 3 !important; // Prevents NavUser from hiding behind OrganizationComponent
	}

	.p-menubar .p-submenu-list .p-menuitem-separator {
		border-top: 1px solid #dadada;
		margin: 0;
	}

	.p-menubar .p-submenu-list .p-submenu-icon {
		font-size: 0.875rem;
	}

	@media screen and (width <= 960px) {
		.p-menubar {
			position: relative;
		}

		.p-menubar .p-menubar-button {
			display: flex;
			width: 2rem;
			height: 2rem;
			color: $tt-menu-item-text-color;
			border-radius: 50%;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		}

		.p-menubar .p-menubar-button:hover {
			color: $tt-menu-item-text-color;
			background: $tt-list-item-background-color-hover;
		}

		.p-menubar .p-menubar-button:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem #e4e9ec;
		}

		.p-menubar .p-menubar-root-list {
			position: absolute;
			display: none;
			padding: 0;
			background: $tt-header-background-color;
			border: 1px solid $tt-list-item-background-color-active;
			box-shadow: 0 0 6px 0 rgba(0 0 0 / 16%);
			width: 100%;
		}

		.p-menubar .p-menubar-root-list .p-menuitem-separator {
			border-top: 1px solid $tt-border-color;
			margin: 0;
		}

		.p-menubar .p-menubar-root-list .p-submenu-icon {
			font-size: 0.875rem;
		}

		.p-menubar .p-menubar-root-list .p-menuitem {
			width: 100%;
			position: static;
		}

		.p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
			margin-left: auto;
			transition: transform 0.2s;
		}

		.p-menubar .p-menubar-root-list .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
			transform: rotate(-180deg);
		}

		.p-menubar .p-menubar-root-list .p-submenu-list {
			width: 100%;
			position: static;
			box-shadow: none;
			border: 0 none;
		}

		.p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
			transition: transform 0.2s;
			transform: rotate(90deg);
		}

		.p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
			transform: rotate(-90deg);
		}

		.p-menubar .p-menubar-root-list .p-menuitem {
			width: 100%;
			position: static;
		}

		.p-menubar .p-menubar-root-list ul li a {
			padding-left: 2.571rem;
		}

		.p-menubar .p-menubar-root-list ul li ul li a {
			padding-left: 4.285rem;
		}

		.p-menubar .p-menubar-root-list ul li ul li ul li a {
			padding-left: 5.999rem;
		}

		.p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
			padding-left: 7.713rem;
		}

		.p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
			padding-left: 9.427rem;
		}

		.p-menubar.p-menubar-mobile-active .p-menubar-root-list {
			display: flex;
			flex-direction: column;
			top: 100%;
			left: 0;
			z-index: 1;
		}
	}

	/* ContextMenu */
	.p-contextmenu {
		background: $tt-color-white;
		box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
		color: $tt-menu-item-text-color;
		padding: 0.25rem;
		width: 12.5rem;
	}

	.p-contextmenu .p-menuitem-link {
		border-radius: 0;
		color: $tt-menu-item-text-color;
		padding: 0.5rem;
		transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
		user-select: none;
	}

	.p-contextmenu .p-menuitem-link .p-menuitem-text {
		color: $tt-menu-item-text-color;
	}

	.p-contextmenu .p-menuitem-link .p-menuitem-icon {
		margin-right: 0.5rem;
	}

	.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
		background: $tt-list-item-background-color-hover;
	}

	.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
		color: $tt-menu-item-text-color-active;
	}

	.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
		color: $tt-menu-item-text-color-active default;
	}

	.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
		color: $tt-menu-item-text-color-active default;
	}

	.p-contextmenu .p-menuitem-link:focus {
		box-shadow: inset 0 0 0 0.2rem #e4e9ec;
		outline: 0 none;
		outline-offset: 0;
	}

	.p-contextmenu .p-submenu-list {
		background: $tt-color-white;
		border: 1px solid $tt-border-color;
		box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
		padding: 0;
	}

	.p-contextmenu .p-menuitem {
		margin: 0;
	}

	.p-contextmenu .p-menuitem:last-child {
		margin: 0;
	}

	.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
		background: $tt-list-item-background-color-hover;
	}

	.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
		color: $tt-menu-item-text-color-active;
	}

	.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
	.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
		color: $tt-menu-item-text-color-active default;
	}

	.p-contextmenu .p-menu-separator {
		border-top: 1px solid $tt-border-color;
		margin: 0;
	}

	.p-contextmenu .p-submenu-icon {
		font-size: 0.75rem;
	}

	/* PanelMenu @sourabh */
	.p-panelmenu .p-panelmenu-header.p-highlight,
	.p-panelmenu .p-panelmenu-header.p-highlight {
		background: $tt-header-background-color;
		border-top: 1px solid $tt-header-border-color;
		color: $tt-header-text-color;
		padding: $tt-header-padding;

		a {
			background-color: $tt-header-background-color-hover;
			padding: $tt-header-padding;
			height: $tt-row-height;
		}
	}

	.p-panelmenu .p-panelmenu-header > a {
		background: $tt-header-background-color;
		border-bottom: 1px solid $tt-header-border-color;
		border-left: 1px solid $tt-header-border-color;
		color: $tt-header-text-color;
		font-weight: 700;
		padding: $tt-header-padding;
		height: $tt-row-height;
	}

	.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
		background: $tt-header-background-color-hover;
		border-color: $tt-header-border-color;
		color: $tt-header-text-color;
	}

	.p-panelmenu .p-panelmenu-content {
		background: $tt-content-background-color;
		color: $tt-header-text-color;
		overflow-x: hidden !important;
		padding: 0;
	}

	.p-panelmenu .p-panelmenu-content .p-menuitem {
		cursor: pointer !important;
		border-bottom: 1px solid $tt-border-color;

		&:hover {
			background-color: $tt-color-hover
		}
	}

	.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
		color: $tt-header-text-color;
		padding: $tt-header-padding;
		font-size: 1rem;
	}

	.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
		color: $tt-header-text-color;
		font-size: 0.875rem;
		font-weight: 500;
		padding-left: 0;
	}

	.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
		color: $tt-header-text-color;
		margin-right: 0.5rem;
	}

	.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
		box-shadow: inset 0 0 0 0.2rem #e4e9ec;
		outline: 0 none;
		outline-offset: 0;
	}

	.p-overlaypanel {
		background: $tt-content-background-color;
		border: 1px solid $tt-header-border-color;
		box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
		color: $tt-header-text-color;
	}

	.p-overlaypanel .p-overlaypanel-content {
		padding: 0.571rem 1rem;
	}

	.p-overlaypanel .p-overlaypanel-close {
		background: $tt-header-background-color;
		border: 1px solid $tt-header-border-color;
		border-radius: 50%;
		color: $tt-header-text-color;
		height: 2rem;
		position: absolute;
		right: -1rem;
		top: -1rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		width: 2rem;
	}

	.p-overlaypanel .p-overlaypanel-close:enabled:hover {
		background: $tt-header-background-color-hover;
		color: $tt-header-text-color;
	}

	/* Dropdown */
	.p-dropdown {
		border: 1px solid $tt-border-color;
		border-bottom-right-radius: $tt-border-radius;
		border-top-right-radius: $tt-border-radius;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}

	.p-dropdown:not(.p-disabled):hover {
		border-color: $tt-input-text-border-color-hover;
	}

	.p-dropdown:not(.p-disabled).p-focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #e4e9ec;
		border-color: $tt-input-text-border-color-focus;
	}

	.p-dropdown.p-dropdown-clearable .p-dropdown-label {
		padding-right: 1.429rem;
	}

	.p-dropdown .p-dropdown-label {
		align-items: center;
		background: transparent;
		border: 0 none;
		display: flex;
		padding: 0.5rem;
	}

	.p-dropdown .p-dropdown-label.p-placeholder {
		color: $tt-text-color-muted;
	}

	.p-dropdown .p-dropdown-label:focus, .p-dropdown .p-dropdown-label:enabled:focus {
		outline: 0 none;
		box-shadow: none;
	}

	.p-dropdown .p-dropdown-trigger {
		background: transparent;
		color: $tt-color-secondary;
		width: 2.357rem;
		border-top-right-radius: $tt-border-radius;
		border-bottom-right-radius: $tt-border-radius;
	}

	.p-dropdown .p-dropdown-clear-icon {
		background: $tt-button-cancel-background-color;
		border-radius: $tt-border-radius;
		color: $tt-color-black;
		font-size: 0.6rem;
		font-weight: bold;
		padding: 0.2rem;
		right: 3rem;
	}

	.p-dropdown.p-invalid.p-component {
		border-color: $tt-border-color-error;
	}

	.p-dropdown-panel {
		background: $tt-content-background-color;
		border: 1px solid $tt-header-border-color;
		box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
		color: $tt-header-text-color;
	}

	.p-dropdown-panel .p-dropdown-header {
		border-bottom: 1px solid $tt-header-border-color;
		color: $tt-header-text-color;
		padding: 0.5rem 0.857rem;
	}

	.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
		padding-right: 1.429rem;
		margin-right: -1.429rem;
	}

	.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
		right: 0.5rem;
		color: $tt-color-secondary;
	}

	.p-dropdown-items {
		padding: 0;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
		background: transparent;
		border: 0 none;
		border-radius: 0;
		color: $tt-header-text-color;
		margin: 0;
		padding: 0.5rem 0.875rem;
		transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
		background: $tt-background-color-hover;
		color: $tt-header-text-color;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
		background: $tt-background-color-hover;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
		color: $tt-text-color;
		background: $tt-background-color-focus;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
		color: $tt-text-color-hover;
		background: $tt-background-color-hover;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
		margin: 0;
		padding: 0.857rem;
		color: $tt-text-color;
		background: $tt-color-white;
		font-weight: 700;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
		padding: 0.5rem 0.857rem;
		color: $tt-color-secondary;
		background: transparent;
	}

	/* DatePicker */
	.p-datepicker.p-component {
		padding: 0;

		.p-datepicker-header {
			border-left: 0 none;
			border-radius: 0;
			border-right: 0 none;
			border-top: 0 none;

			a {
				&:hover {
					border-width: 1px;
				}
			}
		}

		.p-datepicker-calendar {
			margin: 0;

			thead th {
				background-color: #f6f8fa;
			}

			td {
				border-bottom: 1px solid rgb(213 213 213 / 50%);

				a {
					&.p-highlight {
						background-color: #d6d6d6;
						color: $tt-text-color;
					}

					&.p-highlight {
						background-color: $tt-background-color-active;
						color: $tt-color-white;
					}
				}
			}

			tr:last-child td {
				border-bottom: 0 none;
			}
		}

		.p-monthpicker {
			a.p-highlight {
				background-color: $tt-color-blue-500;
				color: $tt-color-white;
			}
		}

		.p-timepicker {
			border-bottom: 0 none;
			border-left: 0 none;
			border-radius: 0;
			border-right: 0 none;
		}

		&.p-datepicker-timeonly {
			.p-timepicker {
				border-top: 0 none;
			}
		}
	}

	/* Steps */
	.p-steps .p-steps-item.p-highlight .p-menuitem-link {
		color: $tt-color-white;
	}

	/* Dialog */
	.p-dialog.p-component .p-dialog-titlebar {
		padding: 1em 1.5em;
	}

	.p-dialog.p-component .p-dialog-titlebar .p-dialog-title {
		font-size: 1.25em;
	}

	.p-dialog.p-component .p-dialog-content {
		padding: $tt-content-padding;
	}

	.p-dialog {
		border: 1px solid $tt-header-border-color;
		border-radius: 2px;
		box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
	}

	.p-dialog .p-dialog-header {
		background: $tt-header-background-color;
		color: $tt-header-text-color;
		padding: $tt-header-padding;
	}

	.p-dialog .p-dialog-header .p-dialog-title {
		font-size: 1rem;
		margin-left: 0.3rem;
	}

	.p-dialog .p-dialog-header .p-dialog-header-icon {
		background: transparent;
		border: 0 none;
		border-radius: 50%;
		color: $tt-header-text-color;
		height: 2rem;
		margin-right: 0.5rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		width: 2rem;
	}

	.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
		background: transparent;
		border-color: transparent;
		color: $tt-header-text-color;
	}

	.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
		box-shadow: 0 0 0 0.2rem #e4e9ec;
		outline: 0 none;
		outline-offset: 0;
	}

	.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
		margin-right: 0;
	}

	.p-dialog .p-dialog-content {
		background: $tt-color-white;
		color: $tt-header-text-color;
		padding: $tt-content-padding;
	}

	.p-dialog .p-dialog-footer {
		background: $tt-content-background-color;
		color: $tt-header-text-color;
		padding: $tt-content-padding;
		text-align: right;
	}

	.p-dialog .p-dialog-footer button {
		margin: 0 0.5rem 0 0;
		min-width: 7rem;
		padding: 0.6rem 1rem;
	}

	.p-dialog-mask.p-component-overlay {
		background-color: rgb(215 222 228 / 50%);
	}

	/* ConfirmDialog */
	.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
		font-size: 2rem !important;
	}

	.p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
		width: 2rem;
		height: 2rem;
	}

	.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
		margin-left: 1rem;
	}

	/* Schedule */
	.fc {
		.fc-button-group {
			.p-highlight {
				background: $tt-color-blue-500;
				border: 1px solid $tt-color-blue-600;
				color: $tt-color-white;
			}
		}
	}

	/* Divider */
	.p-divider .p-divider-content {
		background-color: $tt-color-white;
	}

	.p-divider.p-divider-horizontal {
		margin: 1rem 0;
		padding: 0 1rem;
	}

	.p-divider.p-divider-horizontal::before {
		border-top: 1px $tt-border-color;
	}

	.p-divider.p-divider-horizontal .p-divider-content {
		padding: 0 0.5rem;
	}

	.p-divider.p-divider-vertical {
		margin: 0 1rem;
		padding: 1rem 0;
	}

	.p-divider.p-divider-vertical::before {
		border-left: 1px $tt-border-color;
	}

	.p-divider.p-divider-vertical .p-divider-content {
		padding: 0.5rem 0;
	}

	/* Fieldset */
	.p-fieldset {
		background: $tt-content-background-color;
		border: 1px solid $tt-header-border-color;
		border-radius: 0;
		color: $tt-content-text-color;
		padding: $tt-content-padding !important;
	}

	.p-fieldset .p-fieldset-legend {
		background: $tt-header-background-color;
		border: 1px solid $tt-header-border-color;
		border-radius: 0;
		color: $tt-content-text-color;
		font-weight: 700;
		padding: 0.55rem 1rem;
	}

	.p-fieldset,
	.p-fieldset .p-fieldset-legend {
		padding: 0.7em 1em;
	}

	.p-fieldset-legend > a,
	.p-fieldset-legend > span {
		align-items: center !important;
		color: $tt-content-text-color;
		display: flex !important;
		justify-content: start !important;
	}

	/* Sidebar */
	.p-sidebar {
		background: $tt-color-white;
		border: 1px solid $tt-border-color;
		box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
		color: $tt-color-black;
		padding: 0.571rem 1rem;
	}

	.p-sidebar .p-sidebar-header .p-sidebar-close,
	.p-sidebar .p-sidebar-header .p-sidebar-icon {
		background: transparent;
		border: 0 none;
		border-radius: 50%;
		color: #a6a6a6;
		height: 2rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		width: 2rem;
	}

	.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
	.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
		background: transparent;
		border-color: transparent;
		color: #666;
	}

	.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
	.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
		box-shadow: 0 0 0 0.2rem #e4e9ec;
		outline: 0 none;
		outline-offset: 0;
	}

	.p-sidebar .p-sidebar-header + .p-sidebar-content {
		padding-top: 0;
	}

	/* Tooltip */
	.p-tooltip {
		.p-tooltip-text {
			background-color: $tt-tooltip-background-color;
			border-radius: 0.25rem;
			box-shadow: rgba(0 0 0 / 13.3%) 0 3.2px 7.2px 0, rgba(0 0 0 / 11%) 0 0.6px 1.8px 0;
			color: $tt-text-color;
			padding: 0.5rem;
		}
	}

	.p-tooltip.p-tooltip-right .p-tooltip-arrow {
		border-right-color: $tt-tooltip-background-color;
	}

	.p-tooltip.p-tooltip-left .p-tooltip-arrow {
		border-left-color: $tt-tooltip-background-color;
	}

	.p-tooltip.p-tooltip-top .p-tooltip-arrow {
		border-top-color: $tt-tooltip-background-color;
	}

	.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
		border-bottom-color: $tt-tooltip-background-color;
	}

	.pi-home,
	.pi-fw,
	.pi-plus {
		text-shadow: 0.115rem 0.115rem 0.115rem rgb(0 0 0 / 10%);
	}

	.p-menuitem-icon {
		text-shadow: 0.03rem 0.03rem 0 rgb(0 0 0 / 30%);
	}

	.p-dropdown-filter {
		padding: 0.2rem;
	}

	.p-dropdown-filter-icon.pi.pi-search {
		color: $text-muted;
		right: 0.5rem;
		top: 1.2em;
	}

	.p-dropdown-trigger {
		border-left: 1px solid $tt-header-border-color;
		padding: 0 0.3rem;

		&:hover {
			background: $tt-color-hover;
		}
	}

	/* Chips */
	.p-chips:not(.p-disabled):hover .p-chips-multiple-container {
		border-color: $tt-input-text-border-color-hover;
	}

	.p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #e4e9ec;
		border-color: $tt-input-text-border-color-focus;
	}

	.p-chips .p-chips-multiple-container {
		padding: 0.5rem;
		gap: 0.5rem;
	}

	.p-chips .p-chips-multiple-container .p-chips-token {
		padding: 0.25rem 0.5rem;
		background: #dadada;
		color: $tt-text-color;
		border-radius: 16px;
	}

	.p-chips .p-chips-multiple-container .p-chips-token.p-focus {
		background: #cbcbcb;
		color: $tt-text-color;
	}

	.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
		color: $tt-autocomplete-token-icon-color;
		margin-left: 0.5rem;
	}

	.p-chips .p-chips-multiple-container .p-chips-input-token {
		padding: 0.25rem 0;
	}

	.p-chips .p-chips-multiple-container .p-chips-input-token input {
		font-family: var(--font-family);
		font-feature-settings: var(--font-feature-settings, normal);
		font-size: 1rem;
		color: $tt-text-color;
		padding: 0;
		margin: 0;
	}

	p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
		border-color: $tt-input-border-color-error;
	}

	p-chips.p-chips-clearable .p-inputtext {
		padding-right: 1.429rem;
	}

	p-chips.p-chips-clearable .p-chips-clear-icon {
		color: $tt-color-secondary;
		right: 0.5rem;
	}

	/* Card */
	.p-card {
		box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
		border-radius: $tt-border-radius;
		background: $tt-color-white;
		color: $tt-color-black;
		cursor: pointer;
	}

	.p-card .p-card-body {
		padding: 1rem;
	}

	.p-card .p-card-title {
		font-size: 1rem;
		font-weight: 700;
		margin-bottom: 0.5rem;
		display: -webkit-box; /* stylelint-disable-line */
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.p-card .p-card-subtitle {
		font-weight: 700;
		margin-bottom: 0.5rem;
		color: $text-muted;
	}

	.p-card .p-card-content {
		margin: 1rem 0;
	}

	.p-card .p-card-footer {
		padding: 1rem 0 0;
		border-top: 1px solid $tt-border-color;
	}

	.p-overlaypanel-content {
		.p-datatable .p-datatable-tbody > tr {
			border-left: none !important;
			border-right: none !important;
		}

		.p-datatable .p-datatable-tbody > tr > td {
			border-left: none !important;
		}

		.p-button.p-button-icon-only.p-button-rounded {
			border: none;
			box-shadow: none;
		}

		.p-dropdown {
			width: 100%;
			min-width: 10rem;
			height: 1.8rem;
		}

		.dropdown {
			border: none;
			margin-top: 0.6rem;

			.p-dropdown-trigger {
				display: none;
			}
		}

		.p-dropdown.p-focus {
			box-shadow: none;
			background: transparent;
		}
	}

	.p-datatable > .p-datatable-wrapper {
		overflow: inherit !important;
	}

	body {
		.p-autocomplete .p-autocomplete-dropdown .p-button-icon-left::before,
		.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon::before,
		.p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon::before,
		.p-splitbutton.p-buttonset .p-splitbutton-menubutton .p-button-icon-left::before,
		.p-menuitem-link span.p-submenu-icon::before {
			content: '';
		}
	}

	/* Work-around `resources/primeng.css` bug: https://github.com/primefaces/primeng/issues/9741
	 * Change from Bootstrap `input-group` to PrimeNG `p-inputGroup` when resolved
	 */
	.p-autocomplete, .p-autocomplete-multiple-container,
	.p-chips, .p-chips-multiple-container {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		width: 100%;
	}

	.p-inputgroup .p-inputtext,
	.p-fluid .p-inputgroup .p-inputtext,
	.p-inputgroup .p-inputwrapper,
	.p-inputgroup .p-inputwrapper > .p-component {
		flex: 1 1 auto;
		width: 100%;
	}
}
