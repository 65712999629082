@import '../variables';

/* https://atomiks.github.io/tippyjs/v6/themes/ */
.tippy-box[data-theme~='tasktrain'] {
	color: $tt-text-color;
	box-shadow: 0 0 20px 4px rgb(154 161 177 / 15%), 0 4px 80px -8px rgb(36 40 47 / 25%), 0 4px 4px -2px rgb(91 94 105 / 15%);
	background-color: $tt-tooltip-background-color;
	padding: 0.25rem;
}

.tippy-box[data-theme~='tasktrain'][data-placement^='top'] > .tippy-arrow::before {
	border-top-color: $tt-tooltip-background-color;
}

.tippy-box[data-theme~='tasktrain'][data-placement^='bottom'] > .tippy-arrow::before {
	border-bottom-color: $tt-tooltip-background-color;
}

.tippy-box[data-theme~='tasktrain'][data-placement^='left'] > .tippy-arrow::before {
	border-left-color: $tt-tooltip-background-color;
}

.tippy-box[data-theme~='tasktrain'][data-placement^='right'] > .tippy-arrow::before {
	border-right-color: $tt-tooltip-background-color;
}

.tippy-box[data-theme~='tasktrain'] > .tippy-backdrop {
	background-color: white;
}

.tippy-box[data-theme~='tasktrain'] > .tippy-svg-arrow {
	fill: $tt-tooltip-background-color;
}
