@import '../variables';

// # Fonts #

$web-font-path: 'https://fonts.googleapis.com/css?family=Roboto:400,500,700' !default;

@import url($web-font-path);

$font-family-sans-serif: 'Roboto',
-apple-system,
blinkmacsystemfont,
'Segoe p',
roboto,
'Helvetica Neue',
arial,
sans-serif,
'Apple Color Emoji',
'Segoe p Emoji',
'Segoe p Symbol' !default;

body * {
	font-size: $tt-font-size;
}

// Prevent Zooming on entering inputs on mobile displays
@media (width >= 310px) and (width <= 720px) {
	.p-inplace-content,
	input,
	textarea,
	.ql-editor,
	.p-inputtext {
		font-size: 16px !important;
		font-weight: normal;
	}
}

a,
a:hover,
a:focus,
a:visited {
	cursor: pointer;
	text-decoration: none;
}

// @ToDo: Update PrimeNG disabled styling & replace this Bootstrap Element Disabled/No Pointer Class (while still allowing tooltips via pointer-events)
.disabled {
	opacity: 0.5;

	// Disabled menu items in context menus
	a.p-menuitem-link {
		cursor: not-allowed;

		&:hover,
		&:focus,
		&:active {
			cursor: not-allowed;
		}
	}
}

input,
textarea {
	padding-left: 0.5rem;

	&:not(:disabled):hover {
		border-color: $tt-input-text-border-color-hover;
	}
}

// # Application-wide CSS class definitions #

// ## TaskTrain brand ##

.tt-logo-green {
	color: $tt-brand-orange;
}

.tt-logo-blue {
	color: $tt-brand-blue;
}

.tt-logo-font {
	font-family: $tt-brand-font;
	font-weight: bold;
	letter-spacing: 0.1rem;
}

// ## TaskTrain Miscellaneous ##

.tt-cursor-pointer {
	cursor: pointer;
}

// ## Shared Among Multiple Components ##

.tt-start-panel-title {
	font-size: 1.5rem;
}

.tt-motto div,
.tt-motto em {
	font-size: 3rem;
	line-height: 2.688rem;
}

.tt-hide {
	display: none;
}

.tt-scrollable {
	height: inherit;
	overflow-y: auto;
	position: relative;
	margin-bottom: 1.6rem;

	@media screen and (width <= 576px) {
		margin-bottom: 0 !important;
	}
}

.p-sortable-column-icon {
	float: right;
	margin-top: 3px;
}

.p-cell-data {
	word-wrap: break-word;
}

.tt-list-container {
	height: 100%;
	max-height: calc(100vh - 5.5rem);
	overflow: auto;
}

.tt-list-container-with-searchbar {
	max-height: calc(100vh - 8.1rem);
	overflow: auto;
}

.tt-list-container-with-filter {
	max-height: calc(100vh - 20rem);
	overflow: auto;
}

// List group item

.list-group-item { /* stylelint-disable-line selector-class-pattern */
	margin-bottom: 0;
	outline: none;
	background-color: white;

	&:hover {
		background-color: $tt-list-item-background-color-hover;
		box-shadow: none;
		outline: none;
	}
}

.list-group-item.active { /* stylelint-disable-line selector-class-pattern */
	background-color: $tt-list-item-background-color-active;
	color: $tt-menu-item-text-color-active;
	margin-bottom: 0;
	outline: none;
}

// ### PrimeNg panel's custom scroll ###
.tt-panel-scroll {
	min-height: 100%;
	position: relative;

	.p-panel-content {
		overflow-y: auto;
		padding: 0;
		width: 100%;
	}
}

// ### PrimeNG custom panel ###

.tt-custom-panel {
	.p-chkbox-box {
		height: 1em;
		width: 1em;
	}

	.p-chkbox-box.p-highlight {
		background: $tt-checkbox-background-color;
		border: 1px solid $tt-border-color;
		color: $tt-checkbox-color;
	}

	.p-component-header {
		border-bottom: none;
	}

	.p-panel-content-wrapper {
		background: $tt-header-background-color;
		padding: 0 10px 10px;
	}
}

// ### PrimeNG editable dropdown column & Table ###
.tt-organization-role-column,
.tt-manual-role-column {
	min-width: 8rem;
	text-align: left;
	width: 10rem;

	.p-dropdown {
		border: none;

		.p-dropdown-trigger {
			border-left: none;
		}

		.p-dropdown-trigger .fa {
			margin-top: 0.4em;
		}

		.p-inputtext {
			background: transparent;
			font-size: $tt-font-size !important;
		}
	}

	.p-clickable:hover {
		text-shadow: 0.125rem 0.125rem 0.125rem $text-muted;
	}

	.p-inputtext.p-focus,
	.p-multiselect.p-focus,
	.p-dropdown.p-focus,
	.p-inputtext:focus {
		background: none;
		box-shadow: none;
		outline: none;
	}
}

.p-autocomplete-dropdown {
	background-color: $tt-autodropdown-menu-background-color;
	color: $tt-menu-disclosure-icon-color;
	height: 2.3rem;
	width: 2rem;
}

.tt-panel-scroll-margin {
	margin: $tt-content-margin !important;
}

.tt-text-warning {
	color: $tt-color-warning;
}

.tt-text-danger {
	color: $tt-color-danger;
}

.tt-categorization-keyword-chips {
	.p-autocomplete-token, .p-chips-token {
		background-color: $tt-categorization-keyword-background-color;

	}
}

.tt-categorization-function-chips {
	.p-autocomplete-token {
		background-color: $tt-categorization-function-background-color;
	}
}

.tt-categorization-sector-chips {
	.p-autocomplete-token {
		background-color: $tt-categorization-sector-background-color;
	}
}

.tt-categorization-keyword-chip {
	background-color: $tt-categorization-keyword-background-color;
}

.tt-categorization-function-chip {
	background-color: $tt-categorization-function-background-color !important;
}

.tt-categorization-sector-chip {
	background-color: $tt-categorization-sector-background-color !important;
}

.tt-highlight-on-hover:hover {
	background: $tt-color-hover;
}

.tt-list-padding-left {
	padding-left: 0.75rem;
}

.tt-list-padding-right {
	padding-right: 0.75rem;
}

.tt-priority-highest {
	color: $tt-color-red-500 !important;
}

.tt-priority-high {
	color: $tt-color-red-300 !important;
}

.tt-priority-normal {
	color: $tt-color-black !important;
}

.tt-priority-low {
	color: $tt-color-gray-600 !important;
}

.tt-priority-lowest {
	color: $tt-color-gray-500 !important;
}
